import React from "react";
import { useTranslation } from 'react-i18next';
import "./styles.css";
import LightGallery from "lightgallery/react";

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import lgZoom from "lightgallery/plugins/zoom";

const GridGallery = ({ images, tour }) => {
    const { t } = useTranslation(['components']);
    return (
        <div className="" id="grid-galery">
            <LightGallery
                speed={200}
                plugins={[lgZoom]}
                elementClassNames="grid-container light-gallery"
                download={false}
            >
                {images.map((img, index) => (
                    <a
                        key={`image${index + 1}`}
                        href={img.imagen}
                        className={`
                            ${index < 4 ? `image${index + 1}` : "hide-img"} 
                            ${index !== 3 ? "" : ""}`
                        }
                        data-lg-size="900-500"
                    >
                        <img
                            alt={`${img?.caption} ${tour} ${index + 1}`}
                            className={`grid-img ${index === 3 ? "img-lowbrightness" : ""}`}
                            src={img.imagen}

                        />
                        {index === 3 ? <p className="last-img_text">{t('gallery-seemore')}</p> : null}
                    </a>
                ))}
            </LightGallery>
        </div>
    );
};

export default GridGallery;
