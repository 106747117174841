import React, { useState } from "react";
import {
    AppBar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Container,
    Dialog,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Pagination,
    Select,
    Toolbar,
    Typography
} from "@mui/material";
import { ArrowBack, NavigateNext } from '@mui/icons-material';
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Hero2 from "../../components/DestinationHero";
import Filters from "../../components/Filters";
import ProductCard2 from "../../components/DestinationProductCard";
import { dataPage } from "../../utils/destinationPageMockData";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg"
import MainLayout from "../../components/MainLayout";
import useFetchTourList from "../../hooks/API/Tours/useFetchTourList";



const DynamicContent = ({ content }) => {
    return (
        content.map(item => (
            <Typography
                key={item.value}
                sx={{ py: '10px' }}
                variant={item.variant}
                component={item.component}
            >{item.value}</Typography>
        ))
    )
}

const GroupLinks = ({ links }) => (
    links.map(link => (
        <Button
            key={link.text}
            sx={{
                fontSize: '14px',
                mx: '5px',
                my: '6px'
            }}
            variant="contained"
            href={link.href}
        >{link.text}</Button>
    ))
)

const Destination = () => {
    let params = useParams();
    const [orderBy, setOrderBy] = useState('precio');
    const [open, setOpen] = React.useState(false);

    useFetchTourList(params.city);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const {
        hero,
        breadcrumbs,
        activities,
        description,
        numberActivities,
        content,
        atracciones,
        guias } = dataPage;
    const [page, setPage] = React.useState(1);

    const handleChangePage = (event, value) => {
        setPage(value);
    };
    return (
        <MainLayout>
            <Helmet>
                <title>Destinos - ToursPrivados</title>
                <meta
                    name="description"
                    content="Descubre más de 400 destinos y 20.000 actividades únicas. Encuentra las mejores experiencias personalizadas en todos los destinos que siempre soñaste explorar."
                    data-react-helmet="true"
                />
            </Helmet>
            <Helmet>
                <meta
                    name="robots"
                    content="index, follow"
                    data-react-helmet="true"
                />
            </Helmet>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                color="secondary"
            >
                <AppBar sx={{
                    pr: "0!important",
                    position: 'relative',
                    backgroundColor: '#ffffff'
                }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="primary"
                            onClick={handleClose}
                            aria-label="close"

                        >
                            <ArrowBack />
                        </IconButton>
                    </Toolbar>
                    <Box sx={{}}>
                        <Filters />
                    </Box>
                </AppBar>
            </Dialog>
            <Hero2 {...hero} />
            <Container sx={{ maxWidth: 'none!important', pt: '50px', mx: '0px', backgroundColor: '#F0F0F1' }}>
                <Grid container spacing={4}>
                    <Grid item xs={3} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Filters />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Breadcrumbs
                            separator={<NavigateNext fontSize="small" />}
                            aria-label="breadcrumb"
                            sx={{ pb: '20px' }}
                        >
                            {breadcrumbs.map(item => (
                                <Link
                                    key={item.text}
                                    underline="hover"
                                    color={item.isCurrent ? "secondary" : "primary"}
                                    href={item.href}
                                    sx={{
                                        fontWeight: 500,
                                    }}
                                >
                                    {item.text}
                                </Link>
                            ))}
                        </Breadcrumbs>
                        <Typography
                            sx={{ fontSize: '14px' }}
                            variant="body2"
                            component="p"
                        >
                            {description}
                        </Typography>
                        <Divider sx={{ pt: '15px' }} />
                        <Box sx={{ pt: '20px' }}>
                            <Box
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                    mb: '15px'
                                }}>
                                <Typography
                                    sx={{ fontSize: '14px' }}
                                    color="#666B6E"
                                    variant="body2"
                                    component="h2"
                                >
                                    {numberActivities} activities en {hero.city}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    pb: "20px",
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                <Typography
                                    sx={{ fontSize: '14px', display: { xs: 'none', md: 'block' } }}
                                    color="#666B6E"
                                    variant="body2"
                                    component="h2"
                                >
                                    {numberActivities} activities en {hero.city}
                                </Typography>
                                <Button
                                    onClick={handleClickOpen}
                                    sx={{
                                        color: '#666B6E',
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: '5px',
                                        fontWeight: '200'
                                    }}
                                >
                                    <Filter />&nbsp;Filtros
                                </Button>
                                <FormControl>
                                    <InputLabel id="order-by-labe">Ordenar por</InputLabel>
                                    <Select
                                        sx={{ width: '120px', height: '40px' }}
                                        labelId="order-by-label"
                                        id="order-by-labe"
                                        value={orderBy}
                                        label="Ordenar por"
                                        onChange={() => { }}
                                    >
                                        <MenuItem value='precio'>Precio</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            {activities.map((activity, index) => (
                                <ProductCard2 key={index} {...activity} />
                            ))}
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    pt: '15px'
                                }}>
                                <Pagination
                                    count={10}
                                    page={page}
                                    variant="outlined"
                                    color="primary"
                                    onChange={handleChangePage}
                                />
                            </Box>
                            <Card sx={{ mt: '30px' }}>
                                <CardContent>
                                    <DynamicContent content={content} />
                                </CardContent>
                            </Card>
                            <Box
                                sx={{
                                    my: '30px',
                                    textAlign: 'center'
                                }}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold'
                                    }}
                                    variant="h5"
                                    component='h2'
                                >{atracciones.title}</Typography>
                                <Box sx={{ pt: '20px' }}>
                                    <GroupLinks links={atracciones.atraccionesLinks} />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    my: '30px',
                                    textAlign: 'center'
                                }}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold'
                                    }}
                                    variant="h5"
                                    component='h2'
                                >{guias.title}</Typography>
                                <Typography
                                    variant="body2"
                                    component="p"
                                >
                                    {guias.description}
                                </Typography>
                                <Box sx={{ pt: '20px' }}>
                                    <GroupLinks links={guias.guiasLinks} />
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </MainLayout>
    )
}

export default Destination;