import dayjs from "dayjs";

export const getAssistantsString = (pax) => {
    return pax
        .filter(passenger => passenger.value > 0) // Ignorar si el value es 0
        .map(passenger => `${passenger.value} ${passenger.title}${passenger.value > 1 ? 's' : ''}`) // Formatear texto
        .join(', '); // Unir con comas
}

export const getTotalAssistants = (pax) => {
    return pax.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0);
}



export const prePrareTourBookingData = (tour, date, items) => {
    const getCoverOrDefault = (array, condition) => {
        return array.find(condition) || array[0] || null;
    };
    const condition = image => image.isCover;
    let cover = getCoverOrDefault(tour.images, condition);

    console.log('cover', items);

    const totalPrice = items.reduce((total, item) => total + (item.price * item.value), 0);

    const tourtoBook = {
        name: tour.name,
        slug: tour.slug,
        city: tour.city.slug,
        country: tour.country.slug,
        cover: cover.imagen,
        booking: items,
        total: totalPrice,
        extras: {
            activities: []
        },
        date: dayjs(date).format('YYYY-MM-DD')
    }

    return tourtoBook

}