import React from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Container, Divider, Typography, Grid } from '@mui/material'
import { Phone, EmailOutlined } from '@mui/icons-material'
import Form from '../Form'
import './styles.css'


const ContactUs = () => {
    const { t } = useTranslation(['aboutus']);
    return (
        <div className='bg-container'>
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' }
                }}>
                <Box
                    sx={{
                        width: { xs: '100%', md: '100%' }
                    }}>
                    <Typography
                        color="primary.contrastText"
                        sx={{ lineHeight: 1.2, mb: '2rem', textAlign: { xs: 'center', md: 'left' } }}
                        variant="h1"
                        component="h2"
                    >
                        {t('talktous-title')}
                    </Typography>
                    <Grid container columnSpacing={1} rowSpacing={1}>
                        <Grid
                            xs={12}
                            md={4}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mb: '1rem'
                            }}>
                            <Box sx={{ display: 'flex', gap: 1, height: '9rem' }}>
                                <Box sx={{ width: '10%' }}>
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            backgroundColor: '#ffffff',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            height: '2.5rem',
                                            p: '1rem',
                                            width: '2.5rem'
                                        }}>
                                        <Phone color="primary" />
                                    </Box>
                                </Box>
                                <Box sx={{ width: '90%' }}>
                                    <Typography
                                        color="primary.contrastText"
                                        component="h3"
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: 'Poppins',
                                            fontWeight: 500,
                                            lineHeight: 1.2,
                                            mb: '.6rem'
                                        }}>
                                        {t('talktous-phone-title')}
                                    </Typography>
                                    <Typography
                                        color="primary.contrastText"
                                        sx={{
                                            fontSize: 14,
                                            lineHeight: 1.5,
                                            mb: '.6rem'
                                        }}>
                                        {t('talktous-phone-text')}
                                    </Typography>
                                    <Typography
                                        color="primary"
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            mb: '6rem'
                                        }}>
                                        {t('talktous-phone-contact')}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            xs={12}
                            md={4}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mb: '1rem'
                            }}>
                            <Box sx={{ display: 'flex', gap: 1, height: '8rem' }}>
                                <Box sx={{ width: '10%' }}>
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            backgroundColor: '#ffffff',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            height: '2.5rem',
                                            p: '1rem',
                                            width: '2.5rem'
                                        }}>
                                        <EmailOutlined color="primary" />
                                    </Box>
                                </Box>
                                <Box sx={{ width: '90%' }}>
                                    <Typography
                                        color="primary.contrastText"
                                        component="h3"
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: 'Poppins',
                                            fontWeight: 500,
                                            lineHeight: 1.2,
                                            mb: '.6rem'
                                        }}>
                                        {t('talktous-email1-title')}
                                    </Typography>
                                    <Typography
                                        color="primary.contrastText"
                                        sx={{
                                            fontSize: 14,
                                            lineHeight: 1.5,
                                            mb: '.6rem'
                                        }}>
                                        {t('talktous-email1-text')}
                                    </Typography>
                                    <Typography
                                        color="primary"
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            mb: '6rem'
                                        }}>
                                        {t('talktous-email1-contact')}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            xs={12}
                            md={4}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mb: '1rem'
                            }}>
                            <Box sx={{ display: 'flex', gap: 1, height: '9rem', mb: { xs: '2rem', md: 0 } }}>
                                <Box sx={{ width: '10%' }}>
                                    <Box
                                        sx={{
                                            alignItems: 'center',
                                            backgroundColor: '#40C5E8',
                                            borderRadius: '50%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            height: '2.5rem',
                                            p: '1rem',
                                            width: '2.5rem'
                                        }}>
                                        <EmailOutlined color="white" />
                                    </Box>
                                </Box>
                                <Box sx={{ width: '90%' }}>
                                    <Typography
                                        color="secondary.contrastText"
                                        component="h3"
                                        sx={{
                                            fontSize: 18,
                                            fontFamily: 'Poppins',
                                            fontWeight: 500,
                                            lineHeight: 1.2,
                                            mb: '.6rem'
                                        }}>
                                        {t('talktous-email2-title')}
                                    </Typography>
                                    <Typography
                                        color="primary.contrastText"
                                        sx={{
                                            fontSize: 14,
                                            lineHeight: 1.5,
                                            mb: '.6rem'
                                        }}>
                                        {t('talktous-email2-text')}
                                    </Typography>
                                    <Typography
                                        color="primary"
                                        sx={{
                                            fontSize: 16,
                                            fontWeight: 600,
                                            mb: '6rem'
                                        }}>
                                        {t('talktous-email2-contact')}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>


                    {/* <Divider color="white" height={1} sx={{ mb: 3 }} /> */}

                </Box>
                {/* <Box
                    sx={{
                        width: { xs: '100%', md: '60%' }
                    }}>
                    <Form />
                </Box> */}
            </Container>
        </div>
    )
}

export default ContactUs
