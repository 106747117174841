import React from 'react'
import { useTranslation } from 'react-i18next';
import {
    Box, Button, Container, Collapse, Divider, Link, Typography
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import SocialLinksSection from './SocialLinksSection'
import PayMethodsSection from './PayMethodsSection'
import { ReactComponent as TrekzyLogo } from '../../assets/images/trekzy-logo.svg'

const Footer = () => {
    const { t } = useTranslation(['footer', 'paths']);
    const { i18n } = useTranslation();
    const [expandSections, setExpandSection] = React.useState([
        {
            expanded: true,
            name: t('paths:guides-title'),
            content: [
                { link: `/${t('guides')}`, linkText: t('footer:all-guides') },
                {
                    link: i18n.language == 'es' ? '/guias/barcelona/informacion-principal-de-barcelona' : '/guides/barcelona/main-information-barcelona',
                    linkText: i18n.language == 'es' ? 'Guía de Barcelona' : 'Barcelona Guide'
                },
                {
                    link: i18n.language == 'es' ? '/nueva-york/informacion-principal-de-nueva-york' : '/guides/new-york/main-information-new-york',
                    linkText: i18n.language == 'es' ? 'Guía de Nueva York' : 'New York Guide'
                },
                {
                    link: i18n.language == 'es' ? '/guias/madrid/informacion-principal-de-madrid' : '/guides/madrid/main-information-madrid',
                    linkText: i18n.language == 'es' ? 'Guía de Madrid' : 'Madrid Guide'
                },
                {
                    link: i18n.language == 'es' ? '/guias/paris/paris-main-information' : '/guides/paris/main-information-paris',
                    linkText: i18n.language == 'es' ? 'Guía de Paris' : 'Paris Guide'
                }
            ]
        },
        // {
        //     expanded: false,
        //     name: 'Especialidades',
        //     content: [
        //         { link: '/blog', linkText: 'Blog de viaje' },
        //         { link: '/sorprendeme', linkText: 'Sorpréndeme' }
        //     ]
        // },
        {
            expanded: false,
            name: 'Trekzy',
            content: [
                {
                    link: `/${t('paths:contact')}`,
                    linkText: t('paths:contact-title')
                },
                {
                    link: `/${t('paths:aboutus')}`,
                    linkText: t('paths:aboutus-title')
                },
                {
                    link: `/${t('paths:help')}`,
                    linkText: t('paths:help-title')
                },
                {
                    link: `/${t('paths:terms-and-conditions')}`,
                    linkText: t('paths:terms-and-conditions-title')
                },
                {
                    link: `/${t('paths:privacy-policy')}`,
                    linkText: t('paths:privacy-policy-title')
                },
                {
                    link: `/${t('paths:policy-cancellation')}`,
                    linkText: t('paths:policy-cancellation-title')
                }
            ]
        }
    ])

    const onColapseSection = (index) => () => {
        const updatedSections = expandSections
        updatedSections[index].expanded = !expandSections[index].expanded
        setExpandSection([...updatedSections])
    }

    return (
        <Box
            sx={{
                backgroundColor: '#F0F0F1',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                pt: '6rem',
                pb: '5rem'
            }}>
            <TrekzyLogo />
            <SocialLinksSection />
            {expandSections.map((section, index) => (
                <Container sx={{ mb: '1rem' }} key={section.name}>
                    <Button
                        endIcon={
                            section.expanded ? <ExpandMore /> : <ExpandLess />
                        }
                        variant="text"
                        sx={{
                            color: '#223F7F',
                            fontFamily: 'Poppins',
                            fontSize: '18px',
                            fontWeight: 700,
                            width: '100%'
                        }}
                        onClick={onColapseSection(index)}>
                        {section.name}
                    </Button>
                    <Divider
                        aria-hidden="true"
                        color="secondary"
                        sx={{ height: 2 }}
                    />
                    <Collapse
                        in={expandSections[index].expanded}
                        timeout="auto"
                        unmountOnExit>
                        <Box
                            sx={{
                                color: '#666B6E',
                                display: 'flex',
                                flexDirection: 'column',
                                fontFamily: 'Inter',
                                fontSize: '1rem',
                                textAlign: 'center'
                            }}>
                            {section?.content?.map((cont, index) => {
                                const { link, linkText } = cont
                                return (
                                    <Link
                                        key={index}
                                        sx={{ py: '.5rem' }}
                                        underline="none"
                                        color="inherit"
                                        href={link}>
                                        {linkText}
                                    </Link>
                                )
                            })}
                        </Box>
                    </Collapse>
                </Container>
            ))}
            <PayMethodsSection />
            <Container
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    color: '#666B6E',
                    fontSize: '14px',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                }}>
                <Link
                    sx={{ textWrap: 'nowrap', mr: '1rem', mb: '1rem' }}
                    rel="nofollow"
                    color="grayText"
                    underline="none"
                    href={`/${t('paths:terms-and-conditions')}`}>
                    {t('paths:terms-and-conditions-title')}
                </Link>
                <Link
                    sx={{ textWrap: 'nowrap', mb: '1rem' }}
                    rel="nofollow"
                    color="grayText"
                    underline="none"
                    href={`/${t('paths:privacy-policy')}`}>
                    {t('paths:privacy-policy-title')}
                </Link>
                <Link
                    sx={{ textWrap: 'nowrap' }}
                    rel="nofollow"
                    color="grayText"
                    underline="none"
                    href={`/${t('paths:policy-cancellation')}`}>
                    {t('paths:policy-cancellation-title')}
                </Link>
            </Container>
            <Container>
                <Box>
                    <Divider
                        aria-hidden='true'
                        color='secondary'
                        sx={{ height: 2, mb: '1rem', mt: '1rem', width: '100%' }}
                    />
                    <Typography
                        textAlign="center"
                        sx={{
                            color: "#666B6E",
                            fontSize: "14px",
                            textAlign: { xs: "center" },
                            my: "1rem",
                        }}
                    >
                        Powered by <b>Trekzy Booking Engine</b>
                    </Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default Footer
