import React from 'react'
import axios from 'axios'

const useFetchAttractions = (city) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [attractions, setAttractions] = React.useState([]);
    const [error, setError] = React.useState(null);

    const fetchAttractions = React.useCallback(async () => {
        if (!city) return;
        setIsLoading(true)
        const { REACT_APP_BASE_URL } = process.env
        const token = localStorage.getItem('token');

        try {
            const { data } = await axios.get(
                `${REACT_APP_BASE_URL}/index.php?r=api/attractions/listfilter`,
                {
                    params: {
                        city: city,
                    },
                    headers: {
                        'x-authorization-token': token
                    }
                }
            )

            setIsLoading(false)

            if (data) {
                setAttractions(data)
            }
        } catch (err) {
            console.error('Error fetching attractions ', err)
            setError(true)
            setIsLoading(false)
        }
    }, [city])

    React.useEffect(() => {
        if (city) {  // Solo hacer fetch si `name` tiene un valor
            fetchAttractions();
        }
    }, [fetchAttractions]);

    return {
        attractions,
        isLoading,
        error
    }
}

export default useFetchAttractions