import React from 'react'
import { useTranslation } from 'react-i18next';
import {
    Box,
    Container,
    Divider,
    Link,
    List,
    ListItem,
    Typography
} from '@mui/material'
import PayMethodsSection from './PayMethodsSection'
import SocialLinksSection from './SocialLinksSection'
import FooterImage from '../../assets/images/trekzy-footer.png'
import { ReactComponent as TrekzyLogo } from '../../assets/images/trekzy-logo.svg'

const DeskopFooter = () => {
    const { t } = useTranslation(['footer', 'paths']);
    const { i18n } = useTranslation();

    return (
        <Box
            sx={{
                backgroundColor: '#F0F0F1',
                width: '100%'
            }}>
            <Container
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    pt: '6rem',
                    pb: '2rem',
                    position: 'relative',
                }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        paddingRight: '35%',
                    }}>
                    <TrekzyLogo
                        style={{ height: 40, width: 125, marginBottom: '.8rem' }}
                    />
                    <Divider
                        aria-hidden='true'
                        color='secondary'
                        sx={{ height: 2, mb: '2rem', width: 150 }}
                    />
                    <Box
                        sx={{
                            color: '#666B6E',
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4, 1fr)',
                            fontFamily: 'Inter',
                            fontSize: '1rem',
                            mb: '2rem'
                        }}>
                        <Box>
                            <Typography color='secondary' variant='h5' component="span">
                                {t('paths:guides-title')}
                            </Typography>
                            <List sx={{ padding: 0 }}>
                                <ListItem sx={{ padding: 0 }}>
                                    <Link
                                        sx={{ py: '.5rem' }}
                                        color='inherit'
                                        underline='none'
                                        href={`/${t('guides')}`}>
                                        {t('footer:all-guides')}
                                    </Link>
                                </ListItem>
                                <ListItem sx={{ padding: 0 }}>
                                    <Link
                                        sx={{ py: '.5rem' }}
                                        color='inherit'
                                        underline='none'
                                        href={i18n.language == 'es' ? '/guias/barcelona/informacion-principal-de-barcelona' : '/guides/barcelona/main-information-barcelona'}>
                                        {i18n.language == 'es' ? 'Guía de Barcelona' : 'Barcelona Guide'}
                                    </Link>
                                </ListItem>
                                <ListItem sx={{ padding: 0 }}>
                                    <Link
                                        sx={{ py: '.5rem' }}
                                        color='inherit'
                                        underline='none'
                                        href={i18n.language == 'es' ? '/nueva-york/informacion-principal-de-nueva-york' : '/guides/new-york/main-information-new-york'}>
                                        {i18n.language == 'es' ? 'Guía de Nueva York' : 'New York Guide'}
                                    </Link>
                                </ListItem>
                                <ListItem sx={{ padding: 0 }}>
                                    <Link
                                        sx={{ py: '.5rem' }}
                                        color='inherit'
                                        underline='none'
                                        href={i18n.language == 'es' ? '/guias/madrid/informacion-principal-de-madrid' : '/guides/madrid/main-information-madrid'}>
                                        {i18n.language == 'es' ? 'Guía de Madrid' : 'Madrid Guide'}
                                    </Link>
                                </ListItem>
                                <ListItem sx={{ padding: 0 }}>
                                    <Link
                                        sx={{ py: '.5rem' }}
                                        color='inherit'
                                        underline='none'
                                        href={i18n.language == 'es' ? '/guias/paris/paris-main-information' : '/guides/paris/main-information-paris'}>
                                        {i18n.language == 'es' ? 'Guía de Paris' : 'Paris Guide'}
                                    </Link>
                                </ListItem>
                            </List>
                        </Box>
                        <Box>
                            <Typography color='secondary' variant='h5' component="span">
                                Trekzy
                            </Typography>
                            <List sx={{ padding: 0 }}>
                                <ListItem sx={{ padding: 0 }}>
                                    <Link
                                        sx={{ py: '.5rem' }}
                                        color='inherit'
                                        underline='none'
                                        href={`/${t('paths:contact')}`}>
                                        {t('paths:contact-title')}
                                    </Link>
                                </ListItem>
                                <ListItem sx={{ padding: 0 }}>
                                    <Link
                                        sx={{ py: '.5rem' }}
                                        color='inherit'
                                        underline='none'
                                        href={`/${t('paths:aboutus')}`}>
                                        {t('paths:aboutus-title')}
                                    </Link>
                                </ListItem>
                            </List>
                        </Box>
                        <Box>
                            <List sx={{ padding: 0 }}>
                                <ListItem sx={{ padding: 0 }}>
                                    <Link
                                        sx={{ py: '.5rem' }}
                                        color='inherit'
                                        underline='none'
                                        href={`/${t('paths:help')}`}>
                                        {t('paths:help-title')}
                                    </Link>
                                </ListItem>
                                <ListItem sx={{ padding: 0 }}>
                                    <Link
                                        rel="nofollow"
                                        sx={{ py: '.5rem' }}
                                        color='inherit'
                                        underline='none'
                                        href={`/${t('paths:terms-and-conditions')}`}>
                                        {t('paths:terms-and-conditions-title')}
                                    </Link>
                                </ListItem>
                                <ListItem sx={{ padding: 0 }}>
                                    <Link
                                        rel="nofollow"
                                        sx={{ py: '.5rem' }}
                                        color='inherit'
                                        underline='none'
                                        href={`/${t('paths:privacy-policy')}`}>
                                        {t('paths:privacy-policy-title')}
                                    </Link>
                                </ListItem>
                                <ListItem sx={{ padding: 0 }}>
                                    <Link
                                        rel="nofollow"
                                        sx={{ py: '.5rem' }}
                                        color='inherit'
                                        underline='none'
                                        href={`/${t('paths:policy-cancellation')}`}>
                                        {t('paths:policy-cancellation-title')}
                                    </Link>
                                </ListItem>
                            </List>
                        </Box>
                    </Box>
                    <Divider
                        aria-hidden='true'
                        color='secondary'
                        sx={{ height: 2, mb: '1rem', width: '100%' }}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}>
                        <PayMethodsSection />
                        <SocialLinksSection />
                    </Box>
                    <Box>
                        <Divider
                            aria-hidden='true'
                            color='secondary'
                            sx={{ height: 2, mb: '1rem', width: '100%' }}
                        />
                        <Typography
                            textAlign="center"
                            sx={{
                                color: "#666B6E",
                                fontSize: "14px",
                                textAlign: { xs: "center" },
                                my: "1rem",
                            }}
                        >
                            {t('powered-bytrekzy1')} <b>{t('powered-bytrekzy2')}</b>
                        </Typography>
                    </Box>
                </Box>
                <img
                    src={FooterImage}
                    style={{
                        width: '30rem',
                        height: '30rem',
                        position: 'absolute',
                        top: '10%',
                        left: '62%'
                    }}
                    alt="tours privados personalizados by trekzy"
                />
            </Container>
        </Box>
    )
}

export default DeskopFooter
