import React from "react";
import { useTranslation } from 'react-i18next';
import Header from "../Header";
import Footer from "../Footer";
// import { pages } from '../../utils/data'
import trekzyLogo from '../../assets/images/trekzy-logo.svg'

const MainLayout = ({ children }) => {
    const { t } = useTranslation('paths');
    const pages = [
        {
            text: t('destinations-title'),
            url: `/${t('destinations')}`
        },
        {
            text: t('guides-title'),
            url: `/${t('guides')}`
        }
    ]

    return (
        <>
            <Header logoImgSource={trekzyLogo} pages={pages} />
            {children}
            <Footer />
        </>
    )
}

export default MainLayout;