import React, { useCallback, useEffect, useState, useRef } from "react"
import { useTranslation } from 'react-i18next';
import {
    Alert,
    Skeleton,
    Box,
    Container,
    Divider,
    Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel
} from "@mui/material"
import dayjs from "dayjs"

import { Helmet } from "react-helmet";
import MainLayout from "../../components/MainLayout"
import Breadcrumbs from "../../components/Breadcrumbs"
import TagButton from "../../components/TagButton"
import ReviewRatings from "../../components/Tours/ReviewRatings"
import GridGallery from "../../components/GridGallery"
import DetailsSection from "../../components/Tours/DetailsSection"
import ItineraryItem from "../../components/Tours/ItineraryItem"
import Calendar from "../../components/Filters/Calendar"
import Schedule from "../../components/Filters/Schedule"
import RadioButtonsFilter from "../../components/Filters/RadioButtonsFilter"
import AddItemsButtonsFilter from "../../components/Filters/AddItemsButtonsFilter"
import PaymentSummary from "../../components/Tours/PaymentSummary"
import CouponBox from "../../components/Tours/CouponBox"
import AdditionalInformationSection from "../../components/Tours/AdditionalInformationSection"
import FAQs from "../../components/Tours/FAQs"
import MediaCard from "../../components/ProductCard3"
import Reviews from "../../components/Tours/Reviews"
import { InfoOutlined } from "@mui/icons-material"

import { useScreenDetector } from "../../hooks/useScreenDetector"

import { ReactComponent as MedalIcon } from "../../assets/icons/medal.svg"
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg"
import { ReactComponent as GlobeIcon } from "../../assets/icons/globe.svg"
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg"
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg"
import { ReactComponent as TrashIcon } from "../../assets/icons/trash_white.svg"

import { tourData, topActivities, reviews } from "./tourData"
import { dataPage } from "../../utils/destinationPageMockData"
import useFetchTourDetails from "../../hooks/API/Tours/useFetchTourDetails"
import RichText from "../../components/RichText"
import { Link, useParams } from "react-router-dom"
import ErrorPage from "../Error"
import useQuote from "../../hooks/API/Tours/useQuote"
import axios from "axios"
import useFetchSuggestTours from "../../hooks/API/Tours/useFetchSuggestTours"
import { useDispatch, useSelector } from "react-redux"
import { removeExtraActivities, setExtraActivities } from "../../app/features/cartSlice"
import { prePrareTourBookingData } from "../../utils/checkout"



const Tour = () => {
    const { t } = useTranslation(['tours', 'paths', 'components']);
    const params = useParams();

    const { tourDetails, isLoading, error, setIsLoading } = useFetchTourDetails(params.slug);
    const [tour, setTour] = useState({});
    const [expandedTabs, setExpandedTabs] = React.useState([]);
    const [isDataReady, setIsDataReady] = useState(false);
    const [addedItineraries, setAddedItineraries] = React.useState([]);
    const [date, setDate] = React.useState(dayjs(new Date()).add(3, 'day'));
    const [quote, setQuote] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [availableDate, setAvailableDate] = useState(true);
    const [availabilityHours, setAvailabilityHours] = useState(true);
    const [fetchParams, setFetchParams] = useState(null);
    const [recommendations, setRecommendations] = useState(null);
    const suggestedTours = useFetchSuggestTours(fetchParams);
    const checkoutTours = useSelector(state => state.cart.checkout.tours);
    const [addedTickets, setAddedTickets] = React.useState([]);

    const [participants, setParticipans] = React.useState([])
    const handleScrollToTop = () => {
        window.scrollTo(0, 0); // Mueve la pantalla al inicio (x = 0, y = 0)
    };

    const targetRef = useRef(null);

    const dispatch = useDispatch();

    const AvailabilityButton = ({ styles = {} }) => (
        <TagButton
            text={t('seeAvailability')}
            href="#personalizar-btn"
            onClick={(e) => {
                e.preventDefault();
                console.log("looking for availability")
                targetRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
            }}
            sx={{
                fontWeight: 600,
                mt: "1rem",
                px: "1rem",
                py: ".4rem",
                textAlign: 'center',
                width: "100%",
                ...styles,
            }}
        />
    )

    const rotateDay = (day) => (day === 0 ? 1 : day === 6 ? 0 : day + 1);
    const findNextValidDate = () => {
        let date = dayjs().add(tourDetails.cutoff.value, 'hours'); // Comienza desde el cutoff
        const available = tourDetails.availabilityDays.days.split(',').map(Number); // [1, 2, 3]

        // Encuentra la primera fecha válida
        for (let i = 0; i < 365; i++) { // Limitar a un año para evitar bucles infinitos
            let dayOfWeek = rotateDay(date.day());
            if (tourDetails?.cutoff && available.includes(dayOfWeek) && !date.isBefore(dayjs().add(tourDetails.cutoff.value, 'hours'), 'day')) {
                return date; // Fecha válida encontrada
            }
            date = date.add(1, 'day'); // Avanza al siguiente día
        }
        return null; // Si no se encuentra una fecha válida en un año
    };

    useEffect(() => {
        if (tourDetails && !isLoading) {
            // Establecer el estado del tour
            setTour(tourDetails);

            // Crear breadcrumbs
            setBreadcrumbs([
                {
                    name: `${t('breadcumnb-tours-title', { 'city': tourDetails.city?.name || "..." })}`,
                    path: `/${t('breadcumnb-tours', { 'city': tourDetails.city?.slug })}`,
                },
                {
                    name: tourDetails.name || t('loading'),
                    path: "#",
                },
            ]);

            setFetchParams({ tour: tourDetails.slug, city: tourDetails.city?.slug, limit: 3 })

            if (tourDetails?.cutoff) {
                // console.log("cutoff", tourDetails);
                const nextAvailableDate = findNextValidDate();
                // console.log(nextAvailableDate);
                setDate(nextAvailableDate);
            }

            // Procesar los participantes
            if (tourDetails.paxRates) {
                const paxRates = tourDetails.paxRates.map((item) => ({
                    title: item.pax,
                    subtitle: item.age_range,
                    price: (item.price) ? Math.ceil(item.price.replace(',', '.')) : 0,
                    value: item.value || 0,
                }));
                setParticipans(paxRates);
            }
        }
    }, [tourDetails, isLoading]);

    // Manejar la lógica cuando los datos sugeridos están listos
    useEffect(() => {
        if (suggestedTours?.tourList) {
            const toursRecommended = suggestedTours.tourList.map((tour) => ({
                title: tour.name,
                shortDescription: tour.shortDescription,
                price: tour.price,
                imageUrl: tour.cover,
                rate: tour.scores?.score,
                reviews: tour.reviewsTotal,
                city: { slug: tour.city.slug },
                slug: tour.slug,
                freeCancelation: tour.freeCancelation,
                duration: tour.duration,
                languages: tour.languages
            }));
            setRecommendations(toursRecommended);
        }
    }, [suggestedTours]);

    useEffect(() => {
        if (tourDetails && !isLoading) {
            const timer = setTimeout(() => {
                setIsDataReady(true);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [tourDetails, isLoading]);


    const getTextLanguages = (languages) => {
        if (languages === undefined) return ''
        // return languages.reduce((accumulator, currentValue, index) => {
        //     if (index !== 0) {
        //         return accumulator + ", " + currentValue?.language
        //     }
        //     return accumulator + currentValue?.language
        // }, "");
    };

    const { isMobile } = useScreenDetector()

    const hashTags = topActivities.slice(0, 3)
    const parent = tourData.breadCrums[tourData.breadCrums.length - 1];
    const tourCards = dataPage.activities.slice(0, 3);
    const durationFilters = [
        "3-5 horas",
        "7-9 horas",
        "12-14 horas",
        "Indiferente",
    ]

    const onExpandTab = (tab) => {
        if (expandedTabs.includes(tab)) {
            setExpandedTabs(current => current.filter(item => item !== tab))
        } else {
            setExpandedTabs(current => [...current, tab])
        }
    }

    const addExtraTicket = (item, type) => {
        const ticketKey = `${item.name}_${type}`;

        //remove all the ones starts with the same name
        const filteredTickets = addedTickets.filter(ticket => !ticket.startsWith(item.name));
        if (!addedTickets.includes(ticketKey)) {
            setAddedTickets([...filteredTickets, ticketKey]);
        }
        const tourData = prePrareTourBookingData(tour, date, participants);
        dispatch(setExtraActivities({
            url: tour?.slug,
            tourData: tourData,
            extras: {
                rates: item.rates[type],
                description: item.description,
                name: item.name
            }
        }));
    }

    const removeExtraTicket = (ticketKey, item) => {
        const filteredTickets = addedTickets.filter(ticket => ticket !== ticketKey);
        setAddedTickets(filteredTickets);
        dispatch(removeExtraActivities({
            url: tour?.slug,
            tourData: tour,
            extras: item
        }));
    }

    const onClickItinerary = (name) => {
        if (addedItineraries.find((item) => item.title === name)) {
            setAddedItineraries(current => current.filter(item => item.title !== name))
        } else {
            const found = tourData.itinerary.find((item) => item.title === name)
            setAddedItineraries(current => [...current, found])
        }
    }

    const handleChangeCalendar = (newDate) => {
        setDate(newDate)
    }

    const onModifyParticipants = (instruction) => (title) => {
        setParticipans(current => {
            return current.map(item => {
                if (item.title === title) {
                    const newValue = instruction === "add"
                        ? item.value + 1
                        : item.value - 1;

                    return {
                        ...item,
                        value: newValue,
                    };
                }
                return item;
            });
        });
    }

    useEffect(() => {
        const { REACT_APP_BASE_URL } = process.env
        const token = localStorage.getItem('token');

        const paxConfig = participants.map(item => ({ paxtype: item.title, pax_total: item.value }));

        axios.post(
            `${REACT_APP_BASE_URL}/index.php?r=api/quote&tour=${tour?.slug}`,
            {
                "tour_quote": [
                    {
                        "pax": paxConfig,
                        date_tour: dayjs(date).format('YYYY-MM-DD') // "2024-12-26"
                    }
                ]
            },
            {
                headers: {
                    'x-authorization-token': token,
                    'Content-Type': 'application/json'  // Especificamos que estamos enviando JSON
                }
            }
        ).then(({ data }) => {
            const { tour } = data;
            const { quote } = tour[0];
            if (!quote) setAvailableDate(false);
            if (!quote) return;

            //set cutoff time

            setAvailableDate(true);
            setQuote(data);
        });

    }, [date])

    if (!isDataReady) { // Mostrar esqueletos mientras carga o no hay tour
        return (
            <MainLayout>
                <Box sx={{ backgroundColor: "#ffffff", height: "fit-content", width: "100%" }}>
                    <Container>
                        <Skeleton variant="rectangular" height={40} /> {/* Breadcrumbs */}
                        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", mb: "2rem", mt: "1rem" }}>
                            <Box sx={{ width: "70%" }}>
                                <Skeleton variant="text" width={200} height={40} /> {/* Titulo */}
                                <Skeleton variant="text" width={300} height={20} sx={{ mt: "0.5rem" }} /> {/* Descripcion corta */}
                                <Skeleton variant="rectangular" width={100} height={20} sx={{ mt: "0.5rem" }} /> {/* Rating */}
                            </Box>
                            <Box sx={{ width: "30%", textAlign: "right", pl: "15%" }}>
                                <Skeleton variant="text" width={100} height={40} /> {/* Precio */}
                                <Skeleton variant="rectangular" width={150} height={40} sx={{ mt: "0.5rem" }} /> {/* Boton */}
                            </Box>
                        </Box>
                        <Skeleton variant="rectangular" width="100%" height={300} sx={{ mb: "3rem" }} /> {/* Galeria */}
                        <Skeleton variant="rectangular" width="100%" height={200} sx={{ mb: "2rem" }} /> {/* Detalles del tour */}
                        <Skeleton variant="rectangular" width="100%" height={200} sx={{ mb: "2rem" }} /> {/* Itinerario */}
                        <Skeleton variant="rectangular" width="100%" height={150} sx={{ mb: "2rem" }} /> {/* Calendario/Resumen */}
                        <Skeleton variant="rectangular" width="100%" height={250} sx={{ mb: "3rem" }} />{/* Otras sugerencias */}
                    </Container>
                </Box>
            </MainLayout>
        );
    }


    return (
        error ? <ErrorPage /> :
            <MainLayout>
                <Helmet>
                    <title>{t('page-tour', { 'tour': tour?.name })}</title>
                    <meta
                        name="description"
                        content={tour?.content?.short_description}
                        data-react-helmet="true"
                    />
                </Helmet>
                <Helmet>
                    <meta
                        name="robots"
                        content="index, follow"
                        data-react-helmet="true"
                    />
                </Helmet>

                <Box sx={{
                    backgroundColor: "#ffffff",
                    height: "fit-content",
                    width: "100%",
                }}>
                    <Container>
                        {(breadcrumbs.length > 0) && <Breadcrumbs breadcrumbs={breadcrumbs} />}
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                            mb: { xs: "1rem", md: "2rem" },
                        }}>
                            <Box sx={{ width: "70%" }}>
                                <Typography
                                    component="h1"
                                    sx={{
                                        color: "#3E3A39",
                                        fontSize: { xs: 20, md: 32 },
                                        fontFamily: 'Poppins',
                                        fontWeight: 700,
                                        lineHeight: 1.5,
                                    }}
                                >
                                    {tour?.name}
                                </Typography>
                                <Typography sx={{ display: { xs: "none", md: "block" }, mt: "1rem" }}>
                                    {tour?.content?.short_description}
                                </Typography>
                                <Box sx={{ display: { xs: "none", md: "block" }, mt: "1rem" }} >
                                    <ReviewRatings scores={tour?.scores} reviewsTotal={tour?.reviewsTotal} />
                                </Box>
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "30%",
                                textAlign: "right",
                                pl: "15%",
                            }}>
                                <Typography
                                    color="secondary"
                                    component="p"
                                    sx={{
                                        fontSize: { xs: 20, md: 24 },
                                        fontFamily: 'Poppins',
                                        fontWeight: 700,
                                        lineHeight: 1.5,
                                    }}
                                >
                                    {`${t('from')} ${tour?.rates?.min_price}${tour?.rates?.currency}`}
                                </Typography>
                                <AvailabilityButton styles={{ display: { xs: "none", md: "block" } }} />
                            </Box>
                        </Box>
                        <Box sx={{ mb: { xs: "1rem", md: "3rem" } }}>
                            {tourDetails !== undefined && <GridGallery images={tour.images} tour={tour?.name} />}
                        </Box>
                        <Box sx={{ display: { xs: "block", md: "none" } }}>
                            <RichText
                                text={tour?.content?.short_description}
                            />
                        </Box>
                        <Box sx={{ display: { xs: "block", md: "none" }, mt: "1rem" }} >
                            <ReviewRatings rate={tourData.rate} reviewsCount={reviews.count} />
                        </Box>
                        <AvailabilityButton styles={{ display: { xs: "block", md: "none" }, mb: "2rem" }} />
                        <Box sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            width: "100%"
                        }}>
                            <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                                <DetailsSection
                                    title={t('about-tour')}
                                    isExpanded={
                                        !isMobile ? true : expandedTabs.includes(t('about-tour'))
                                    }
                                    onCollapse={onExpandTab}
                                >
                                    {tour?.content?.policyCancellation && <Box sx={{ my: "2rem" }}>
                                        <Box sx={{
                                            alignItems: "center",
                                            display: "flex",
                                            flexDirection: "row",
                                            mb: "1rem",
                                        }}>
                                            <ClockIcon width={24} height={24} />
                                            <Typography color="secondary" variant="subtitle3" sx={{ ml: "4px" }}>
                                                {
                                                    tourData.freeCancelation ?
                                                        t('freecancelation') :
                                                        t('cancelation-nonrefundable')
                                                }
                                            </Typography>
                                        </Box>
                                        <RichText
                                            styles={{ wfontSize: 16, fontFamily: 'Inter' }}
                                            text={tour?.content?.policyCancellation}
                                        />
                                    </Box>}
                                    <Box sx={{ mb: "2rem" }}>
                                        <Box sx={{
                                            alignItems: "center",
                                            display: "flex",
                                            flexDirection: "row",
                                            mb: "1rem",
                                        }}>
                                            <GlobeIcon width={24} height={24} />
                                            <Typography color="secondary" variant="subtitle3" sx={{ ml: "4px" }}>
                                                {tour?.languages.map((item, index) => {
                                                    return index == 0 ? `${t('language')}: ` + item.language + ` | ${t('alsoavailable')}: ` : (index == 1 ? item.language : `, ` + item.language);
                                                })}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </DetailsSection>
                                <DetailsSection
                                    title={t('tourdetails')}
                                    isExpanded={
                                        !isMobile ? true : expandedTabs.includes(t('tourdetails'))
                                    }
                                    onCollapse={onExpandTab}
                                >
                                    <Box sx={{
                                        borderBottom: "1px solid #666B6E",
                                        display: "flex",
                                        flexDirection: { xs: "column", md: "row" },
                                        mt: "2rem",
                                        width: "100%",
                                        pb: "1rem",
                                    }}>
                                        <Typography component="h3" color="secondary" sx={{
                                            fontSize: 16,
                                            width: { xs: "100%", md: "30%" },
                                        }}>
                                            {t('tourdetails-description')}
                                        </Typography>
                                        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                                            <RichText
                                                styles={{ wfontSize: 16, fontFamily: 'Inter' }}
                                                text={tour?.content?.description}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{
                                        borderBottom: "1px solid #666B6E",
                                        display: "flex",
                                        flexDirection: { xs: "column", md: "row" },
                                        mt: "2rem",
                                        width: "100%",
                                        pb: "1rem",
                                    }}>
                                        <Typography component="h3" color="secondary" sx={{
                                            fontSize: 16,
                                            width: { xs: "100%", md: "30%" },
                                        }}>
                                            {t('tourdetails-included')}
                                        </Typography>
                                        <RichText
                                            styles={{ fontSize: 16, fontFamily: 'Inter' }}
                                            text={tour?.content?.include}
                                        />
                                    </Box>
                                    <Box sx={{
                                        borderBottom: "1px solid #666B6E",
                                        display: "flex",
                                        flexDirection: { xs: "column", md: "row" },
                                        my: "2rem",
                                        width: "100%",
                                        pb: "1rem",
                                    }}>
                                        <Typography component="h3" color="secondary" sx={{
                                            fontSize: 16,
                                            width: { xs: "100%", md: "30%" },
                                        }}>
                                            {t('tourdetails-notincluded')}
                                        </Typography>
                                        <RichText
                                            styles={{ fontSize: 16, fontFamily: 'Inter' }}
                                            text={tour?.content?.notIncluded}
                                        />
                                    </Box>
                                </DetailsSection>
                                {(tour && tour?.extras?.attractions.length > 0) &&
                                    <DetailsSection
                                        title={t('suggesteditinerary')}
                                        isExpanded={
                                            !isMobile ? true : expandedTabs.includes(t('suggesteditinerary'))
                                        }
                                        onCollapse={onExpandTab}
                                    >

                                        {tour && tour?.extras?.attractions.map((item, index) => {



                                            return (
                                                <div key={index} style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                                    <Typography key={`h2_${index}`} component="h3" sx={{
                                                        color: "#223F7F",
                                                        fontFamily: "Poppins",
                                                        fontSize: "18px",
                                                        fontWeight: 700,
                                                        marginTop: "19px",
                                                        marginBottom: "5px",
                                                        display: "flex", // Asegura que el ícono y el texto estén en línea
                                                        alignItems: "center", // Alinea verticalmente ícono y texto
                                                        gap: "8px", // Espaciado entre ícono y texto
                                                    }}>
                                                        {item.icon &&
                                                            <div
                                                                dangerouslySetInnerHTML={
                                                                    {
                                                                        __html:
                                                                            (item.icon) ? item.icon.replace(/height="\d+px"/, 'height="32px"')
                                                                                .replace(/width="\d+px"/, 'width="32px"') : ''
                                                                    }
                                                                }
                                                                style={{ width: "32px", height: "32px" }}
                                                            />
                                                        }

                                                        {item.name}
                                                    </Typography>
                                                    <Typography key={`p_${index}`} component="p" sx={{
                                                        color: "#223F7F",
                                                        fontFamily: "Poppins",
                                                        fontSize: "15px",
                                                        fontWeight: 400,
                                                        marginBottom: "15px",
                                                    }}>
                                                        {item.description}
                                                    </Typography>

                                                    {item.duration &&
                                                        <Typography
                                                            key={`p_clock_${index}`}
                                                            component="p"
                                                            sx={{
                                                                display: "flex",        // Configura el diseño como flexbox
                                                                alignItems: "center",   // Alinea verticalmente el ícono y el texto
                                                                gap: "8px",             // Espaciado entre el ícono y el texto (opcional)
                                                                fontSize: "16px",       // Ajusta el tamaño de la fuente del texto
                                                            }}
                                                        >
                                                            <ClockIcon width={21} height={21} />
                                                            {item.duration}
                                                        </Typography>
                                                    }


                                                    <div key={`div_${index}`} style={{ display: "flex", flexDirection: "row", gap: "1rem", flexWrap: "wrap" }}>
                                                        {item?.ticket_types.map((type, index) => {
                                                            //if activiti is addes change label to 'Ticket type agregado'
                                                            const ticketKey = `${item.name}_${type}`;
                                                            const isAdded = addedTickets.includes(ticketKey);

                                                            return <TagButton
                                                                endIcon={isAdded ? <CheckIcon /> : <PlusIcon />}
                                                                hoverIcon={isAdded ? <TrashIcon /> : <PlusIcon />}
                                                                key={index} text={`${t('addTicket')} ${type}`} onClick={() => isAdded ? removeExtraTicket(ticketKey, item) : addExtraTicket(item, type)} sx={{
                                                                    padding: '5px 15px',
                                                                    backgroundColor: isAdded ? "#223F7F" : "#40C5E8",
                                                                }} />
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </DetailsSection>
                                }

                                <DetailsSection
                                    title={t('additionalinfo')}
                                    isExpanded={
                                        !isMobile ? true : expandedTabs.includes(t('additionalinfo'))
                                    }
                                    onCollapse={onExpandTab}
                                    infoIcon={true}
                                    infoDetails={tour?.operator}
                                >
                                    <Box
                                        sx={{
                                            borderBottom: "1px solid #666B6E",
                                            pb: "1rem",
                                            mt: "1rem",
                                        }}
                                    >

                                        <Typography component="h3" color="secondary" sx={{
                                            fontSize: 16,
                                        }}>
                                            {t('tourdetails-requirements')}
                                        </Typography>
                                        <RichText
                                            styles={{ fontSize: 16, fontFamily: 'Inter' }}
                                            text={tour?.content?.requirement}
                                        />
                                    </Box>

                                    <Box
                                        sx={{
                                            borderBottom: "1px solid #666B6E",
                                            pb: "1rem",
                                            mt: "1rem",
                                        }}
                                    >

                                        <Typography component="h3" color="secondary" sx={{
                                            fontSize: 16,
                                        }}>
                                            {t('tourdetails-instructions')}
                                        </Typography>
                                        <RichText
                                            styles={{ fontSize: 16, fontFamily: 'Inter' }}
                                            text={tour?.content?.instructions}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            borderBottom: "1px solid #666B6E",
                                            pb: "1rem",
                                            mt: "1rem",
                                        }}
                                    >

                                        <Typography component="h3" color="secondary" sx={{
                                            fontSize: 16,
                                        }}>
                                            {t('tourdetails-beforeyougo')}
                                        </Typography>
                                        <RichText
                                            styles={{ fontSize: 16, fontFamily: 'Inter' }}
                                            text={tour?.content?.additionalInformation}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            borderBottom: "1px solid #666B6E",
                                            pb: "1rem",
                                            mt: "1rem",
                                        }}
                                    >

                                        <Typography component="h3" color="secondary" sx={{
                                            fontSize: 16,
                                        }}>
                                            {tour?.isAccessible == 1 ? t('tourisAccessible') : t('tourisNotAccessible')}
                                        </Typography>
                                        <Typography>
                                            {tour?.isAccessible == 1 ?
                                                t('tourisAccessibleText') :
                                                t('tourisNotAccessibleText')
                                            }
                                        </Typography>
                                    </Box>
                                </DetailsSection>

                            </Box>

                            <Box ref={targetRef} sx={{ width: { xs: "100%", md: "30%" } }}>
                                <Box sx={{ mt: { xs: "1rem", md: "2rem" }, pl: { xs: 0, md: "2rem" } }}>
                                    {tour?.availabilityDays &&
                                        <Calendar cutOff={tour?.cutoff?.value} availableDays={tour?.availabilityDays.days} title={t('selectdate')} date={date} handleChange={handleChangeCalendar} />
                                    }
                                </Box>
                                <Box sx={{ mt: { xs: "1rem", md: "2rem" }, pl: { xs: 0, md: "2rem" } }}>
                                    {tour?.availabilityHours &&
                                        <Schedule title={t('selectschedule')} availabilityHours={[tour?.availabilityHours]} ></Schedule>
                                    }
                                </Box>
                                <Box sx={{ mt: { xs: "1rem", md: "2rem" }, pl: { xs: 0, md: "2rem" } }}>
                                    <AddItemsButtonsFilter
                                        title={t('participants')}
                                        options={participants}
                                        onAdd={onModifyParticipants("add")}
                                        onRemove={onModifyParticipants("remove")}
                                        isExpanded={true}
                                    />
                                </Box>
                                <Box sx={{ mt: { xs: "1rem", md: "2rem" }, pl: { xs: 0, md: "2rem" } }}>
                                    <RadioButtonsFilter title={t('components:duration')} options={durationFilters} onChange={() => { }} />
                                </Box>
                                <Box sx={{ mt: { xs: "1rem", md: "2rem" }, pl: { xs: 0, md: "2rem" } }}>
                                    {availableDate ? <>
                                        {participants && <PaymentSummary items={participants} iva={21} tour={tour} date={date} />}
                                    </> : <>
                                        <Alert severity="error">{t('noAvailability')}</Alert>
                                    </>}
                                </Box>

                            </Box>
                        </Box>
                        {/* <AdditionalInformationSection sections={tour?.content}></AdditionalInformationSection> */}
                        <Typography component="h2" color="secondary" sx={{
                            fontSize: { xs: 24, md: 32 },
                            fontFamily: 'Poppins',
                            fontWeight: 'bolder',
                            lineHeight: 1.5,
                            mt: { xs: "3rem", md: "4rem" },
                            mb: { xs: "1rem", md: "2rem" },
                        }}>
                            {t('other-suggestions')}
                        </Typography>
                        <Box sx={{
                            display: "grid",
                            gridTemplateColumns: { xs: "1", md: "repeat(3, 1fr)" },
                            gap: "1rem",
                            width: "100%",
                        }}>

                            {recommendations && recommendations.map((tour, index) => (
                                <Link style={{
                                    textDecoration: 'none',
                                    display: "flex",
                                    justifyContent: "center",

                                }} key={index} onClick={handleScrollToTop} to={`/tour/${tour.city.slug}/${tour.slug}`}>
                                    <MediaCard
                                        key={`${tour.title}${index}`}
                                        {...tour}
                                    />
                                </Link>
                            ))}
                        </Box>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: "2rem"
                        }}>
                            <TagButton
                                text={
                                    isMobile ?
                                        t('seemore') :
                                        t('other-suggestions-button', { "city": tour?.city?.name })
                                }
                                href={`/tours/${tour?.city?.slug}`}
                                color="white"
                                sx={{
                                    borderRadius: 8,
                                    border: "1px solid #40C5E8",
                                    color: "#40C5E8",
                                    px: "1rem",
                                    py: ".4rem",
                                    marginBottom: "3rem",
                                }}
                            />
                        </Box>
                    </Container>
                </Box>
            </MainLayout>
    )
}

export default Tour
