import React from 'react'
import { useTranslation } from 'react-i18next';
import {
    Container,
    Typography
} from '@mui/material'
import { useResolvedPath } from 'react-router-dom'
import { Helmet } from "react-helmet";
// components
import Breadcrumbs from '../../../components/Breadcrumbs'
import Hero from '../../../components/Hero';
import BlogArticleButtons from '../../../components/BlogArticleButtons'
import BlurImageWrapper from '../../../components/BlurImageWrapper'
import { opacities } from '../../../components/BlurImageWrapper'
import DestinationGallery from '../../../components/DestinationGallery'
import MainLayout from '../../../components/MainLayout'
import RichText from '../../../components/RichText'
// hooks
import useFetchAttractionByURL from '../../../hooks/API/Guides/useFetchAttractionByUrl'
import useFetchTopGuides from '../../../hooks/API/Guides/useFetchTopGuides'
// icons
import { ReactComponent as InfoIcon } from '../../../assets/icons/info-white.svg'
import { ReactComponent as CameraIcon } from '../../../assets/icons/camera.svg'
import { ReactComponent as BagpackIcon } from '../../../assets/icons/bagpack.svg'
import { ReactComponent as TicketIcon } from '../../../assets/icons/ticket.svg'
import { ReactComponent as CampTentIcon } from '../../../assets/icons/camp-tent.svg'
import { ReactComponent as PlateIcon } from '../../../assets/icons/plate.svg'
import GuiasBlogSkeleton from '../GuiasBlogSkeleton'
// import '../styles.css'



const AttractionsBlog = ({
    preview = false,
}) => {
    const { t } = useTranslation('guides');

    const apiSections = [
        t("guides-main-information")
    ]

    let paths = [];
    let page, city, slug;
    paths = window.location.pathname.split('/').filter(Boolean)
    if (window.location.pathname.indexOf('preview') === -1) {
        page = paths[0]
        city = paths[1]
        slug = paths[3]
    } else {
        page = paths[1]
        city = paths[2]
        slug = paths[4]
    }
    const [sections, setSections] = React.useState([])
    const [metaDescription, setMetaDescription] = React.useState([])

    const [sectionTitle, setSectionTitle] = React.useState('');
    const { topGuides } = useFetchTopGuides()
    const [pageSection] = apiSections.filter(section => slug.includes(section))

    const { attraction: pageData, isLoading: isPageLoading } = useFetchAttractionByURL(
        city,
        slug,
        preview
    )

    const breadcrumbs = [
        {
            name: t("travel-guides"),
            path: `/${page}`
        },
        {
            name: pageData?.city,
            path: `/${page}/${city}/${t("guides-main-information")}-${city}`
        },
        {
            name: t("guides-attractions-title"),
            path: `/${page}/${city}/${t("guides-attractions")}-${city}`
        },
        {
            name: pageData?.title,
            path: `/${page}/${city}/${t("guides-attractions")}-en-${city}/${slug}`
        },
    ]

    React.useEffect(() => {
        const sectionsData = pageData?.sections || []
        if (sectionsData.length) {
            const placeName = pageData?.city
            const defaultSections = [
                {
                    title: `${t("guides-main-information-title")}`,
                    section: t("guides-main-information-tab"),
                    name: apiSections[0],
                    slug: slug,
                    Icon: InfoIcon
                }
            ]

            setSectionTitle(sectionsData[0]?.section_name);
            // document.title = sectionsData[0]?.title;
            // document.querySelector('meta[name="description"]').setAttribute('content', sectionsData[0]?.description.slice(0, 155))

            setMetaDescription(sectionsData[0]?.description.slice(0, 155));

            const btnSections = defaultSections.map(section => {
                const path = `/${t("guides")}/${city}/${section.slug}`
                return ({
                    ...section,
                    path,
                })
            })
            setSections(btnSections)
        }
    }, [city, pageData, metaDescription])


    return (
        <MainLayout>
            <Helmet>
                <title>{pageData?.title || t("guides-attractions-title")}</title>
                <meta
                    name="description"
                    content={metaDescription}
                    data-react-helmet="true"
                />
            </Helmet>
            <Helmet>
                <meta
                    name="robots"
                    content="index, follow"
                    data-react-helmet="true"
                />
            </Helmet>
            {
                isPageLoading ? (
                    <GuiasBlogSkeleton />
                ) : (
                    <>
                        <Hero
                            bgImage={pageData?.imagen}
                            opacity={opacities.lighter}
                            renderInputSearch={false}
                            titleHeader={t('Attraction')}
                            title={pageData?.title}
                            subText={pageData?.city}
                            type='span'
                        />
                        <Container>
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                            {/* <BlogArticleButtons
                                placeName={pageData?.city}
                                activeSection={slug || 'main-information'}
                                sections={sections}
                                city={city}
                            /> */}
                            <div className='guides-content-container'>
                                <RichText text={pageData?.sections?.htmlcontent || ''} />
                            </div>
                        </Container>
                        <DestinationGallery title={t('destinationGarllery-others')} destinations={topGuides || []} textPosition='center' />
                    </>
                )
            }
        </MainLayout>
    )
}

export default AttractionsBlog
