import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importar archivos de traducción por categoría
import homeES from './es/home.json';
import footerES from './es/footer.json';
import guidesES from './es/guides.json';
import headerES from './es/header.json';
import componentsES from './es/components.json';
import destinationsES from './es/destinations.json';
import pathsES from './es/paths.json';
import toursES from './es/tours.json';
import faqsES from './es/faqs.json';
import aboutusES from './es/aboutus.json';
import checkoutES from './es/checkout.json';

import homeEN from './en/home.json';
import footerEN from './en/footer.json';
import guidesEN from './en/guides.json';
import headerEN from './en/header.json';
import componentsEN from './en/components.json';
import destinationsEN from './en/destinations.json';
import pathsEN from './en/paths.json';
import toursEN from './en/tours.json';
import faqsEN from './en/faqs.json';
import aboutusEN from './en/aboutus.json';
import checkoutEN from './en/checkout.json';

// Obtener idioma desde variables de entorno o default
const language = process.env.REACT_APP_LANGUAGE || 'en';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                home: homeEN,
                footer: footerEN,
                guides: guidesEN,
                header: headerEN,
                components: componentsEN,
                destinations: destinationsEN,
                paths: pathsEN,
                tours: toursEN,
                faqs: faqsEN,
                aboutus: aboutusEN,
                checkout: checkoutEN,
            },
            es: {
                home: homeES,
                footer: footerES,
                guides: guidesES,
                header: headerES,
                components: componentsES,
                destinations: destinationsES,
                paths: pathsES,
                tours: toursES,
                faqs: faqsES,
                aboutus: aboutusES,
                checkout: checkoutES,
            }
        },
        lng: language,
        fallbackLng: 'es', // Si el idioma no existe, usa español
        ns: ['home', 'footer', 'guides', 'header', 'components', 'destinations', 'paths', 'tours', 'faqs', 'aboutus', 'checkout'], // Especifica los namespaces disponibles
        defaultNS: 'home',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
