
import { useTranslation } from 'react-i18next';
import { Button, Container, Typography } from '@mui/material'
import { Helmet } from "react-helmet";
import Hero from '../../components/Hero';
import BlurImageWrapper from '../../components/BlurImageWrapper'
import opacities from '../../components/BlurImageWrapper'
import MainLayout from '../../components/MainLayout'
import faqImage from '../../assets/images/hawai-forest.jpg'
import CustomAccordionComponent from './components/CustomAccordion'
import travelerImage from '../../assets/images/young-traveler.jpg'

const Faqs = () => {
    const { t } = useTranslation(['faqs', 'paths', 'components']);
    const buttonStyles = {
        borderRadius: '16px',
        height: 'fit-content',
        color: '#ffffff',
        display: 'grid',
        textTransform: 'none',
        marginVertical: 4,
        width: '180px',
        '&:hover': {
            backgroundColor: 'secondary.main'
        }
    }
    return (
        <MainLayout>
            <Helmet>
                <title>{t('page')}</title>
                <meta
                    name="description"
                    content={t('metadescription')}
                    data-react-helmet="true"
                />
            </Helmet>
            <Helmet>
                <meta
                    name="robots"
                    content="index, follow"
                    data-react-helmet="true"
                />
            </Helmet>

            <Hero
                bgImage={faqImage}
                opacity={opacities.darker}
                renderInputSearch={false}
                title={t('hero-title')}
                text={t('hero-text')}
            />
            <Container
                sx={{
                    width: { sx: '390px', md: '1240px' },
                    my: { xs: '4rem', sm: '8rem' }
                }}>
                <Typography
                    color="secondary"
                    variant="subtitle"
                    sx={{
                        fontSize: { xs: 22, md: 26 },
                        fontWeight: 600
                    }}>
                    Informacion sobre la empresa y los servicios
                </Typography>
                <CustomAccordionComponent
                    index="01"
                    component="h3"
                    title="¿Cómo describirías brevemente la empresa y su misión?"
                    content="Juntamos personas que quieran un tour personalizado con operadores
                        capaces de configurar el tour a su medida."
                />
                <CustomAccordionComponent
                    index="02"
                    title="¿Qué tipo de tours ofrece ToursPrivados?"
                    content="Todos los tipos de tours privados, desde los más simples a los más elaborados
y creativos. Siempre siguiendo a las necesidades y preferencias de nuestros
clientes en cada detalle."
                />
                <CustomAccordionComponent
                    index="03"
                    title=" Si todavía no sabes a que elegir, experimente nuestra encuesta y
                deja nuestros expertos te sorprendan:"
                    content=""
                    textLink="Encuesta"
                    link="encuesta"
                    padding="1.5rem 1.15rem"
                    top={41}
                />

                <CustomAccordionComponent
                    index="04"
                    title="¿En qué regiones o ciudades están disponibles estos tours?"
                    content="Estamos en los destinos más solicitados. Aquí tienes una lista con todos los
destinos que ofertamos actualmente: "
                    textLink="Destinos"
                    link="/destinos/espana"
                />
                <CustomAccordionComponent
                    index="05"
                    title="¿Qué diferencia hay a tus tours privados de otros servicios similares?"
                    content="Ofrecemos experiencias 100% personalizadas que encajan con tus
necesidades y características. Tanto si viajas solo, con familia o con amigos
nuestros tours están hechos para ver y hacer lo que quieras."
                />
                <div style={{ marginTop: '4rem' }} />
                <Typography
                    color="secondary"
                    variant="subtitle"
                    sx={{
                        fontSize: { xs: 22, md: 26 },
                        fontWeight: 600
                    }}>
                    Proceso de reserva y pago
                </Typography>
                <CustomAccordionComponent
                    index="01"
                    title="¿Cómo pueden los clientes reservar un tour?"
                    content="Primero busca el destino y el tour que quieras hacer, después selecciona la
fecha y configura los de tus acompañantes, el tiempo que quieras realizarlo, la
recogida y si quieres agregar alguna experiencia más. Una vez lo hayas
configurado simplemente resérvalo."
                />
                <CustomAccordionComponent
                    index="02"
                    title="¿Qué métodos de pago acepta ToursPrivados?"
                    content="Tarjeta de crédito, débito y transferencia bancaria.
El pago del tour se realiza íntegramente a través de nuestra plataforma, por lo
que no tendrás que pagar ningún tipo de tasa oculta posterior. ¡Sin sorpresas!"
                />
                <CustomAccordionComponent
                    index="03"
                    title="¿Ofrecen opciones de pago flexibles o planes de pago?"
                    content="Trabajamos con los principales planes disponibles en su región a través de
pasarelas de pago conocidas y fiables."
                />
                <CustomAccordionComponent
                    index="04"
                    title="¿Cómo se confirma la reserva del tour?"
                    content="Instantánea y automáticamente. Nuestro sistema está directamente
conectado con nuestros proveedores para que puedas ver al momento la real
disponibilidad de un tour. De esa manera, todos economizan tiempo y tienen
claro lo que de hecho está comprando."
                />
                <div style={{ marginTop: '4rem' }} />
                <Typography
                    color="secondary"
                    variant="subtitle"
                    sx={{
                        fontSize: { xs: 22, md: 26 },
                        fontWeight: 600
                    }}>
                    Guías turísticos
                </Typography>
                <CustomAccordionComponent
                    index="01"
                    title="¿Cómo se seleccionan y verifican a los guías turísticos?"
                    content="Los guías son
contratados por los operadores turísticos, y son estos últimos quienes se
apuntan a formar parte de ToursPrivados. Para operar a través de
ToursPrivados revisamos la documentación legal y nos aseguramos de
verificar a aquellos que cumplan con todos nuestros requisitos de calidad.
Aquí tienes una página con todos los requisitos para colaborar con
ToursPrivados: "
                    link="#"
                    textLink="Pendiente"
                />
                <CustomAccordionComponent
                    index="02"
                    title="¿Qué tipo de formación o certificaciones tienen los guías?"
                    content="De manera general, los guías necesitan obtener sus certificados locales para
                    que puedan trabajar legalmente en las empresas operadoras. Para eso deben
                    ser debidamente calificados y trabajar de manera profesional en el área."
                />
                <CustomAccordionComponent
                    index="03"
                    title="¿Es posible solicitar guías con habilidades específicas, como el conocimiento
                        de un idioma en particular?"
                    content="Sí, cuando vayas a configurar el tour que quieras hacer elige los idiomas que
                        quieras."
                    padding="2rem 1.18rem"
                    top="50px"
                />
                <div style={{ marginTop: '4rem' }} />
                <Typography
                    color="secondary"
                    variant="subtitle"
                    sx={{
                        fontSize: { xs: 22, md: 26 },
                        fontWeight: 600
                    }}>
                    Personalización de los tours
                </Typography>
                <CustomAccordionComponent
                    index="01"
                    title="¿Pueden los clientes personalizar sus tours? ¿Hasta qué punto?"
                    content="Sí, puedes personalizar cada detalle del tour, desde el idioma, el tiempo, hasta
                    las atracciones que verás, si incluye comida y el nivel de actividad física. Aquí
                    tienes la lista completa de personalizaciones disponibles: "
                    link="#"
                    linkText="Pendiente"
                />
                <CustomAccordionComponent
                    index="02"
                    title="¿Cuánto tiempo de antelación se requiere para realizar modificaciones a un
                    tour reservado"
                    content="Depende del proveedor, pero en general un mínimo de 48 horas."
                    padding="1.45rem 1.15rem"
                    top="41px"
                />
                <div style={{ marginTop: '4rem' }} />
                <Typography
                    color="secondary"
                    variant="subtitle"
                    sx={{
                        fontSize: { xs: 22, md: 26 },
                        fontWeight: 600
                    }}>
                    Políticas de cancelación y cambios
                </Typography>
                <CustomAccordionComponent
                    index="01"
                    title="¿Cuál es la política de cancelación de la empresa?"
                    content="Puedes modificar y cancelar tu tour sin penalizaciones hasta 48 horas antes
del inicio del tour.
Aquí tienes todas las políticas y condiciones de uso: "
                    link="/legales"
                    linkText="Términos y Condiciones"
                />
                <CustomAccordionComponent
                    index="02"
                    title="¿Existen cargos por cancelación o cambios en la reserva?"
                    content="No, solo si lo cancelas o modificas dentro de las 48 horas previas al tour. Aquí
tienes todas las políticas y condiciones de uso: "
                    link="/legales"
                    linkText="Términos y Condiciones"
                />
                <CustomAccordionComponent
                    index="03"
                    title="¿Cómo pueden los clientes realizar cambios en sus reservas?"
                    content="Los clientes deben solicitarlo al departamento de reservas Trekzy en horario
laboral hábil a través del email, whatsapp o teléfono.
Las informaciones de contacto las puedes ver en el voucher de confirmación
del tour o en nuestra pagina: "
                    link="#"
                    linkText="Pendiente"
                />
                <div style={{ marginTop: '4rem' }} />
                <Typography
                    color="secondary"
                    variant="subtitle"
                    sx={{
                        fontSize: { xs: 22, md: 26 },
                        fontWeight: 600
                    }}>
                    Experiencia del usuário:
                </Typography>
                <CustomAccordionComponent
                    index="01"
                    title="¿Qué tipo de soporte al cliente se ofrecen (chat en vivo, teléfono, email)?"
                    content="El equipo Trekzy está siempre disponible para ayudar nuestros clientes en los
principales canales de comunicación, sea por email, teléfono o whatsapp. Aquí
tienes los contactos: "
                    link="/contacto"
                    linkText="Contactos"
                />
                <CustomAccordionComponent
                    index="02"
                    title="¿Existe una aplicación móvil?"
                    content="No, por ahora no tenemos una app ya que nuestra web fue diseñada pensando
                            en la mejor experiencia en todos los dispositivos y solo necesitarás el voucher
                            de confirmación para acceder al tour."
                />
                <CustomAccordionComponent
                    index="03"
                    title="¿Cómo pueden los clientes dejar comentarios o valoraciones sobre su
                            experiencia?"
                    content="Para dejar un comentario ingresa en el tour que has contratado y escribe sobre
                            tu experiencia. ¡Fácil!"
                    padding="1.45rem 1.15rem"
                    top="41px"
                />
                <div style={{ marginTop: '4rem' }} />
                <Typography
                    color="secondary"
                    variant="subtitle"
                    sx={{
                        fontSize: { xs: 22, md: 26 },
                        fontWeight: 600
                    }}>
                    Medidas de seguridad y salud
                </Typography>
                <CustomAccordionComponent
                    index="01"
                    title="¿Qué medidas de seguridad y salud implementan en los tours?"
                    content="Si bien los incidentes son poco frecuentes y nadie espera que ocurran, todos
nuestros proveedores están obligados a contar con un seguro de salud para
emergencias. Estos seguros cumplen con, al menos, los requisitos mínimos
establecidos por la legislación local."
                />
                <CustomAccordionComponent
                    index="02"
                    title="¿Cómo gestionáis las emergencias médicas durante un tour?"
                    content="Los guías turísticos están capacitados para asistir a los clientes de la mejor
manera posible. En caso de necesidad, cuentan con contactos directos en
hospitales, ya que todos son residentes locales y conocen cada rincón de la
región en la que trabajan."
                />
                <div style={{ marginTop: '4rem' }} />
                <Typography
                    color="secondary"
                    variant="subtitle"
                    sx={{
                        fontSize: { xs: 22, md: 26 },
                        fontWeight: 600
                    }}>
                    Promociones y descuentos
                </Typography>
                <CustomAccordionComponent
                    index="01"
                    title="¿Ofrecen promociones, descuentos o programas de fidelidad?"
                    content="¡Por supuesto! Siempre tenemos algún tipo de promoción cargada en nuestra
web para ofrecerte los mejores precios. Además, acompáñanos en las RRSS
para estar siempre bien informado."
                />
                <CustomAccordionComponent
                    index="02"
                    title="¿Pueden los clientes estar informados sobre las ofertas especiales?"
                    content="¡Claro que sí! Apúntate a nuestra newsletter aquí: "
                    link="#"
                    linkText="Pendiente"
                />
                <div style={{ marginTop: '4rem' }} />
                <Typography
                    color="secondary"
                    variant="subtitle"
                    sx={{
                        fontSize: { xs: 22, md: 26 },
                        fontWeight: 600
                    }}>
                    Logística del tour
                </Typography>
                <CustomAccordionComponent
                    index="01"
                    title="¿Qué incluye el precio del tour (transporte, entradas, comidas)?"
                    content="El precio es íntegro y cubre toda la configuración del tour. Quedan fuera de este
precio las comidas que no hayas contratado, compras en tiendas, propina
opcional y daños que puedas haber causado (si no has contratado el seguro)."
                />
                <CustomAccordionComponent
                    index="02"
                    title="¿Cuánto dura normalmente un tour?"
                    content="¡Lo que tu elijas! Puedes contratar un tour desde 1 hora hasta 12 horas."
                />
                <CustomAccordionComponent
                    index="03"
                    title="¿Dónde se encuentran los puntos de encuentro y cómo se comunican a los
                            clientes?"
                    content="Tú mismo eliges el punto de encuentro o de recogida en el momento de
                            reservarlo."
                    padding="1.45rem 1.15rem"
                    top="41px"
                />
                <div style={{ marginTop: '4rem' }} />
                <Typography
                    color="secondary"
                    variant="subtitle"
                    sx={{
                        fontSize: { xs: 22, md: 26 },
                        fontWeight: 600
                    }}>
                    Aspectos legales y de privacidad
                </Typography>
                <CustomAccordionComponent
                    index="01"
                    title="¿Cómo se manejan los datos personales de los clientes?"
                    content="Encriptamos tus datos y los guardamos en una base de datos segura de
                            acuerdo con la legislación."
                />
                <CustomAccordionComponent
                    index="02"
                    title="¿Tienen alguna política de privacidad y términos de servicio claramente
                            definidos?"
                    content="Tanto nuestra política de privacidad como nuestros términos y condiciones
                            están de acuerdo con las leyes locales. Para saber más accede a: link (url de
                            textos legales)"
                    padding="1.45rem 1.15rem"
                    top="41px"
                />
            </Container>
            <BlurImageWrapper imageUrl={travelerImage}>
                <Container
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        py: '5rem'
                    }}>
                    <Typography
                        variant="body1"
                        color="secondary.contrastText"
                        sx={{
                            textAlign: 'center',
                            maxWidth: 1024
                        }}>
                        {t('contact')}
                    </Typography>
                    <Typography
                        color="secondary.contrastText"
                        variant="h2"
                        sx={{ mb: '1rem', fontWeight: 'bold' }}>
                        {t('needhelp')}
                    </Typography>
                    <Button
                        href="/"
                        variant="contained"
                        color="primary"
                        sx={{ ...buttonStyles, marginRight: 0 }}>
                        {t('sendemail')}
                    </Button>
                </Container>
            </BlurImageWrapper>
        </MainLayout>
    )
}
export default Faqs
