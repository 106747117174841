import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AboutUs from './pages/AboutUs';
import Booking from './pages/Booking';
import Checkout from './pages/Checkout';
import CancelationPolicies from './pages/CancelationPolicies';
import Destination from './pages/Destination';
import DestinationsCountry from './pages/DestinationsCountry';
import DestinationsPrincipal from './pages/DestinationsPrincipal';
import ErrorPage from './pages/Error';
import Faqs from './pages/Faqs';
import GuiasDeViaje from './pages/GuiasDeViaje';
import GuiasBlog from './pages/GuiasBlog';
import AttractionsBlog from './pages/GuiasBlog/Attractions';
import Home from './pages/Home';
import PrivacyPolicies from './pages/PrivacyPolicies';
import Tour from './pages/Tour';
import CartMobile from './pages/CartMobile';
import Terms from './pages/Terms';
import TourList from './pages/Tours';

const RouterComponent = () => {
    const { t } = useTranslation('paths');

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Home />
        },
        {
            path: `/${t('paths:help')}`,
            element: <Faqs />
        },
        {
            path: `/${t('paths:checkout')}`,
            element: <Checkout step={0} />
        },
        {
            path: `/${t('paths:checkout')}/${t('paths:checkout-step2')}`,
            element: <Checkout step={1} />
        },
        {
            path: `/${t('paths:checkout')}/${t('paths:checkout-step3')}`,
            element: <Checkout step={2} />
        },
        {
            path: `${t('paths:booking-confirm')}/:confirmation`,
            element: <Booking />
        },
        {
            path: `/${t('cart')}`,
            element: <CartMobile />
        },
        {
            path: `/${t('destinations')}`,
            element: <DestinationsPrincipal />
        },
        {
            path: `/${t('destinations')}/:country`,
            element: <DestinationsCountry />
        },
        {
            path: `/${t('destinations')}/:country/:city`,
            element: <Destination />
        },
        {
            path: `/${t('destinations')}/:country/:city/:slug`,
            element: <Tour />
        },
        {
            path: `/${t('tour')}/:city/:slug`,
            element: <Tour />
        },
        {
            path: `/${t('tours')}/:city`,
            element: <TourList />
        },
        {
            path: `/${t('guides')}`,
            element: <GuiasDeViaje />
        },
        {
            path: `/${t('guides')}/:city`,
            element: <GuiasBlog />
        },
        {
            path: `/${t('guides')}/:city/:slug`,
            element: <GuiasBlog />
        },
        {
            path: `/${t('guides')}/:city/:attracition/:slug`,
            element: <AttractionsBlog />
        },
        {
            path: `/preview/${t('guides')}/:city/:attracition/:slug`,
            element: <AttractionsBlog />
        },
        {
            path: `/preview/${t('guides')}/:city`,
            element: <GuiasBlog preview={true} />
        },
        {
            path: `/preview/${t('guides')}/:city/:slug`,
            element: <GuiasBlog preview={true} />
        },
        {
            path: `/${t('terms-and-conditions')}`,
            element: <Terms />
        },
        {
            path: `/${t('not-found')}`,
            element: <ErrorPage />
        },
        {
            path: `/${t('policy-cancellation')}`,
            element: <CancelationPolicies />
        },
        {
            path: `/${t('privacy-policy')}`,
            element: <PrivacyPolicies />
        },
        {
            path: `/${t('aboutus')}`,
            element: <AboutUs />
        },
        {
            path: '*',
            element: <ErrorPage />
        }
    ]);

    return <RouterProvider router={router} />;
};

export default RouterComponent;
