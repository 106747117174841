import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import {
    AppBar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Container,
    Dialog,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Pagination,
    Select,
    Toolbar,
    Typography
} from "@mui/material";
import { Helmet } from "react-helmet";
import { ArrowBack, NavigateNext } from '@mui/icons-material';
import { useParams } from "react-router-dom";
import Hero2 from "../../components/DestinationHero";
import Filters from "../../components/Filters";
import ProductCard2 from "../../components/DestinationProductCard";
import { dataPage } from "../../utils/destinationPageMockData";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg"
import MainLayout from "../../components/MainLayout";
import useFetchTourList from "../../hooks/API/Tours/useFetchTourList";
import useFetchCategories from "../../hooks/API/Tours/useFetchCategories";
import axios from "axios";



const DynamicContent = ({ content }) => {
    return (
        content.map(item => (
            <Typography
                key={item.value}
                sx={{ py: '10px' }}
                variant={item.variant}
                component={item.component}
            >{item.value}</Typography>
        ))
    )
}

const GroupLinks = ({ links }) => (
    links.map(link => (
        <Button
            key={link.text}
            sx={{
                fontSize: '14px',
                mx: '5px',
                my: '6px'
            }}
            variant="contained"
            href={link.href}
        >{link.text}</Button>
    ))
)

const TourList = () => {
    const { t } = useTranslation(['tours', 'paths', 'components']);
    let params = useParams();
    const [filteredData, setFilteredData] = useState(null);
    const [orderBy, setOrderBy] = useState('precio');
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tours, setTours] = useState([]);
    const [destination, setDestination] = useState({});
    const [error, setError] = useState(null);
    const [hero, setHero] = useState({});
    const [totalReviews, setTotalReviews] = useState(0);
    const [totalTours, setTotalTours] = useState(0);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [content, setContent] = useState('');
    const [filters, setFilters] = useState({});
    const [priceRange, setPriceRange] = useState([]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const {
        activities,
        description,
        numberActivities,
        atracciones,
        guias } = dataPage;
    const [page, setPage] = React.useState(1);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const fetchTourList = async (filters) => {
        setIsLoading(true);
        const { REACT_APP_BASE_URL } = process.env;
        const token = localStorage.getItem('token');

        try {
            const cityName = filters.city || params.city; // Usa la ciudad del filtro si está disponible
            console.log("Fetching tours for city:", cityName, "with filters:", filters);

            const { data } = await axios.post(
                `${REACT_APP_BASE_URL}/index.php?r=api/tours/tourlist`,
                filters,  // Se envía el JSON como payload en POST
                {
                    params: { city: cityName },
                    headers: {
                        'x-authorization-token': token,
                        'Content-Type': 'application/json'
                    }
                }
            );

            setIsLoading(false);

            if (data?.tours) {
                setPriceRange(data.priceRange);
                setTours(data.tours);
                setDestination(data.city);
                let totalReviewsCount = 0;
                let rating = 0;
                data.tours.forEach(tour => {
                    const reviews = tour.reviewsTotal;
                    totalReviewsCount += reviews;

                    if (reviews >= 0 && reviews < 250) {
                        rating += 7;
                    } else if (reviews >= 250 && reviews <= 1000) {
                        rating += 9;
                    } else if (reviews > 1000) {
                        rating += 10;
                    }
                });

                const totalToursRating = (rating / data.tours.length).toFixed(1);

                setHero({
                    country: data.country.name,
                    city: data.city.name,
                    numberComments: totalReviewsCount,
                    media: `${totalToursRating}/10`,
                    numberActivities: data.count,
                    bgImage: data.city.image
                });

                setContent(data.city.content);
                setTotalTours(data.count);
                setBreadcrumbs([
                    { text: t('paths:home-title'), href: '/', isCurrent: false },
                    { text: t('paths:destinations-title'), href: `/${t('paths:destinations')}`, isCurrent: false },
                    { text: data.country.name, href: `/${t('paths:destinations')}/${data.country.slug}`, isCurrent: false },
                    { text: data.city.name, href: '#', isCurrent: true }
                ]);
            }
        } catch (err) {
            console.error('Error fetching tour details ', err);
            setError(true);
            setIsLoading(false);
        }
    };


    useEffect(() => {
        fetchTourList(filters);
    }, [params.city]);

    return (
        <MainLayout>
            <Helmet>
                <title>{destination.name ? t('page', { city: destination.name }) : t('page-dafault')}</title>
                <meta
                    name="description"
                    content={destination.shortDescription}
                    data-react-helmet="true"
                />
            </Helmet>
            <Helmet>
                <meta
                    name="robots"
                    content="index, follow"
                    data-react-helmet="true"
                />
            </Helmet>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                color="secondary"
            >
                <AppBar sx={{
                    pr: "0!important",
                    position: 'relative',
                    backgroundColor: '#ffffff'
                }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="primary"
                            onClick={handleClose}
                            aria-label="close"

                        >
                            <ArrowBack />
                        </IconButton>
                    </Toolbar>
                    <Box>
                        <Filters city={params.city} maxPrice={tours?.maxPrice} onApplyFilters={(newFilters) => {
                            setFilters(newFilters); // Actualiza los filtros
                            fetchTourList(newFilters); // Llama a la API con los nuevos filtros
                        }} />
                    </Box>
                </AppBar>
            </Dialog>
            <Hero2 {...hero} />
            <Container fixed sx={{ pt: '50px' }}>
                <Grid container spacing={4}>
                    <Grid item xs={3} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Box>
                            <Filters city={params.city} priceRange={priceRange} maxPrice={priceRange?.max} onApplyFilters={(newFilters) => {
                                setFilters(newFilters);
                                fetchTourList(newFilters);
                            }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        {breadcrumbs && <Breadcrumbs
                            separator={<NavigateNext fontSize="small" />}
                            aria-label="breadcrumb"
                            sx={{ pb: '20px' }}
                        >
                            {breadcrumbs.map(item => (
                                <Link
                                    key={item.text}
                                    underline="hover"
                                    color={item.isCurrent ? "secondary" : "primary"}
                                    href={item.href}
                                    sx={{
                                        fontWeight: 500,
                                    }}
                                >
                                    {item.text}
                                </Link>
                            ))}
                        </Breadcrumbs>}
                        <Typography
                            sx={{ fontSize: '14px' }}
                            variant="body2"
                            component="p"
                        >
                            {destination.shortDescription}
                        </Typography>
                        <Divider sx={{ pt: '15px' }} />
                        <Box sx={{ pt: '20px' }}>
                            <Box
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                    mb: '15px'
                                }}>
                                <Typography
                                    sx={{ fontSize: '14px' }}
                                    color="#666B6E"
                                    variant="body2"
                                    component="h2"
                                >
                                    {t('list-activities', { activities: totalTours, city: hero.city })}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    pb: "20px",
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                <Typography
                                    sx={{ fontSize: '14px', display: { xs: 'none', md: 'block' } }}
                                    color="#666B6E"
                                    variant="body2"
                                    component="h2"
                                >
                                    {t('list-activities', { activities: totalTours, city: hero.city })}
                                </Typography>
                                <Button
                                    onClick={handleClickOpen}
                                    className="d-none d-sm-block"
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                        color: '#666B6E',
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: '5px',
                                        fontWeight: '200'
                                    }}
                                >
                                    <Filter />&nbsp;{t('components:filters')}
                                </Button>
                                <FormControl>
                                    <InputLabel id="order-by-labe">{t('components:orderby')}</InputLabel>
                                    <Select
                                        sx={{ width: '120px', height: '40px' }}
                                        labelId="order-by-label"
                                        id="order-by-labe"
                                        value={orderBy}
                                        label="Ordenar por"
                                        onChange={() => { }}
                                    >
                                        <MenuItem value='precio'>{t('components:price')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            {(tours.length > 0) && tours.map((activity, index) => (
                                <Link
                                    key={index}
                                    underline="none"
                                    href={`/tour/${activity.city.slug}/${activity.slug}`}
                                >
                                    <ProductCard2 key={index} {...activity} />
                                </Link>
                            ))}
                            <Card id="card-city-summary" sx={{ mt: '30px' }}>
                                <CardContent>
                                    {/* <DynamicContent content={content} /> */}
                                    <div dangerouslySetInnerHTML={{ __html: content }} />
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </MainLayout>
    )
}

export default TourList;