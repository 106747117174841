import { Box, Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import GuideCard from './GuideCard'
import Carousel from 'react-material-ui-carousel'
import { useScreenDetector } from '../../hooks/useScreenDetector'

import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg'
import { ReactComponent as InfoIcon } from '../../assets/icons/information.svg'
import { ReactComponent as CampTentICon } from '../../assets/icons/camp-tent.svg'
import { ReactComponent as BagPackIcon } from '../../assets/icons/bagpack.svg'
import { ReactComponent as PlateIcon } from '../../assets/icons/plate.svg'
import { ReactComponent as AirBallonIcon } from '../../assets/icons/air-ballon.svg'

const GuideCards = () => {
    const { t } = useTranslation('guides');
    const { isMobile } = useScreenDetector()

    const cards = [
        {
            Icon: InfoIcon,
            title: t("guideCards-card1-title"),
            text: t("guideCards-card1-text")
        },
        {
            Icon: CameraIcon,
            title: t("guideCards-card2-title"),
            text: t("guideCards-card2-text")
        },
        {
            Icon: CampTentICon,
            title: t("guideCards-card3-title"),
            text: t("guideCards-card3-text")
        },
        {
            Icon: BagPackIcon,
            title: t("guideCards-card4-title"),
            text: t("guideCards-card4-text")
        },
        {
            Icon: PlateIcon,
            title: t("guideCards-card5-title"),
            text: t("guideCards-card5-text")
        },
        {
            Icon: AirBallonIcon,
            title: t("guideCards-card6-title"),
            text: t("guideCards-card6-text")
        }
    ]
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                py: '3rem',
                textAlign: 'center'
            }}>
            <Typography
                color="secondary"
                variant="subtitle1"
                component="span"
                sx={{
                    fontSize: { xs: 22, md: 24 },
                    fontWeight: 600
                }}>
                {t('guideCards-title')}
            </Typography>
            <Typography color="primary" variant="subtitle" component="h2" sx={{ mb: '2rem' }}>
                {t('guideCards-subtitle')}
            </Typography>
            <Typography
                sx={{
                    color: '#3E3A39',
                    fontFamily: 'Inter',
                    fontSize: { xs: 16, md: 20 },
                    mb: { xs: '1rem', md: '3rem' }
                }}>
                {t('guideCards-text')}
            </Typography>
            {isMobile ? (
                <Carousel
                    autoPlay={false}
                    sx={{ my: '20px', display: { xs: 'block', md: 'none' } }}>
                    {cards.map((card) => (
                        <GuideCard key={card.title} {...card} />
                    ))}
                </Carousel>
            ) : (
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        gap: 2.5,
                        mb: '5rem'
                    }}>
                    {cards.map((card) => (
                        <GuideCard
                            key={card.title}
                            {...card}
                            alignContent="left"
                        />
                    ))}
                </Box>
            )}
        </Container>
    )
}

export default GuideCards
