import React from "react"
import {
    Box,
    Divider,
    Link,
    List,
    ListItem,
    Typography,
} from "@mui/material";
import { ReactComponent as SadIcon } from "../../assets/icons/sad-face.svg"

const ResultsList = ({ title, items }) => (
    <Box>
        <Typography color="primary" variant="body" sx={{ fontWeight: 700 }}>
            {title}
        </Typography>
        <Divider sx={{ mt: ".5rem" }} />
        {items?.length ? (
            <List>
                <>
                    {
                        items.map(item => (
                            <ListItem key={item.name} sx={{
                                padding: 0,
                                "&:hover": {
                                    borderRadius: 2,
                                    backgroundColor: "#f5f5f5",
                                }
                            }}>
                                <Link href={item.path} sx={{ py: ".5rem", color: "#666B6E" }} underline='none'>
                                    {item.name}
                                </Link>
                            </ListItem>
                        ))
                    }
                </>
            </List>
        ) : null}
    </Box>
)

const SuggestionsList = ({ title, items, onSelect }) => (
    <Box>
        <Typography color="primary" variant="body" sx={{ fontWeight: 700 }}>
            {title}
        </Typography>
        <Divider sx={{ mt: ".5rem" }} />
        {items?.length ? (
            <List>
                <>
                    {
                        items.map(item => (
                            <ListItem
                                key={item.name}
                                sx={{
                                    padding: 0,
                                    "&:hover": {
                                        borderRadius: 2,
                                        backgroundColor: "#f5f5f5",
                                    },
                                    cursor: "pointer"
                                }}
                                onClick={() => onSelect(item.name)} // Trigger onSelect on click
                            >
                                <Link href={item.path} sx={{ py: ".5rem", color: "#666B6E" }} underline='none'>
                                    {item.name}
                                </Link>
                            </ListItem>
                        ))
                    }
                </>
            </List>
        ) : null}
    </Box>
)

const PredictionsList = ({ data, value, onSelect }) => {
    const { sugerencias, ...predictions } = data || {};
    const thereIsData = Object.keys(predictions).some((key) => data[key]?.length);

    if (!thereIsData) {
        return (
            <Box
                sx={{
                    p: "1.5rem",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" },
                        gap: "1rem",
                        justifyContent: "center",
                        mb: "1.5rem",
                    }}
                >
                    <SadIcon />
                    <Typography
                        color="secondary"
                        variant="body"
                        sx={{ fontWeight: 700 }}
                    >
                        {`No hay resultados para "${value}"`}
                    </Typography>
                </Box>
                {data?.sugerencias?.length ? (
                    <SuggestionsList
                        title="Quizás quisiste decir..."
                        items={data?.sugerencias}
                        onSelect={onSelect} // Pasamos la función onSelect
                    />
                ) : null}
            </Box>
        );
    }
    return (
        <Box
            sx={{
                p: "1rem",
                width: "100%",
            }}
        >
            {Object.keys(predictions).map((key) => {
                if (!data[key].length) return null;
                return <ResultsList key={key} title={key} items={data[key]} />;
            })}
        </Box>
    );
};

export default PredictionsList
