import React from 'react'
import { useTranslation } from 'react-i18next';
import {
    Container,
    Typography
} from '@mui/material'
import { useResolvedPath } from 'react-router-dom'
import { Helmet } from "react-helmet";
// components
import Breadcrumbs from '../../components/Breadcrumbs'
import Hero from '../../components/Hero';
import BlogArticleButtons from '../../components/BlogArticleButtons'
import BlurImageWrapper from '../../components/BlurImageWrapper'
import { opacities } from '../../components/BlurImageWrapper'
import DestinationGallery from '../../components/DestinationGallery'
import MainLayout from '../../components/MainLayout'
import RichText from '../../components/RichText'
// hooks
import useFetchGuideByURL from '../../hooks/API/Guides/useFetchGuideByUrl'
import useFetchTopGuides from '../../hooks/API/Guides/useFetchTopGuides'
// icons
import { ReactComponent as InfoIcon } from '../../assets/icons/info-white.svg'
import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg'
import { ReactComponent as BagpackIcon } from '../../assets/icons/bagpack.svg'
import { ReactComponent as TicketIcon } from '../../assets/icons/ticket.svg'
import { ReactComponent as CampTentIcon } from '../../assets/icons/camp-tent.svg'
import { ReactComponent as PlateIcon } from '../../assets/icons/plate.svg'
import GuiasBlogSkeleton from './GuiasBlogSkeleton'
import './styles.css'


const GuiasBlog = ({
    preview = false,
}) => {
    const { t } = useTranslation('guides');

    const apiSections = [
        t("guides-main-information"),
        t("guides-what-to-see"),
        t("guides-attractions"),
        t("guides-before-traveling"),
        t("guides-where-to-stay"),
        t("guides-gastronomy")
    ];

    let paths = [];
    let page, city, slug;
    paths = window.location.pathname.split('/').filter(Boolean)
    if (window.location.pathname.indexOf('preview') === -1) {
        page = paths[0]
        city = paths[1]
        slug = paths[2]
    } else {
        page = paths[1]
        city = paths[2]
        slug = paths[3]
    }
    const [sections, setSections] = React.useState([])
    const [metaDescription, setMetaDescription] = React.useState([])

    const [sectionTitle, setSectionTitle] = React.useState('');
    const { topGuides } = useFetchTopGuides()
    const [pageSection] = apiSections.filter(section => slug.includes(section))

    const { guide: pageData, isLoading: isPageLoading } = useFetchGuideByURL(
        city,
        pageSection,
        preview
    )

    const breadcrumbs = [
        {
            name: t("travel-guides"),
            path: `/${t("path")}`
        },
        {
            name: pageData?.city,
            path: `/${t("path")}/${city}/${t("guides-main-information")}-${city}`
        },
    ]

    React.useEffect(() => {
        const sectionsData = pageData?.sections || []
        if (sectionsData.length) {
            const placeName = pageData?.city
            const defaultSections = [
                {
                    title: `${t("guides-main-information-title")} ${placeName}`,
                    section: t("guides-main-information-tab"),
                    name: apiSections[0],
                    slug: apiSections[0] + '-' + pageData?.city_slug,
                    Icon: InfoIcon
                },
                {
                    title: `${t("guides-what-to-see-title")} ${placeName}`,
                    section: t("guides-what-to-see-tab"),
                    name: apiSections[1],
                    slug: apiSections[1] + '-' + pageData?.city_slug,
                    Icon: CameraIcon
                },
                {
                    title: `${t("guides-attractions-title")} ${placeName}`,
                    section: t("guides-attractions-tab"),
                    name: apiSections[2],
                    slug: apiSections[2] + '-' + pageData?.city_slug,
                    Icon: BagpackIcon
                },
                {
                    title: `${t("guides-before-traveling-title")} ${placeName}`,
                    section: t("guides-before-traveling-tab"),
                    name: apiSections[3],
                    slug: apiSections[3] + '-' + pageData?.city_slug,
                    Icon: TicketIcon
                },
                {
                    title: `${t("guides-where-to-stay-title")} ${placeName}`,
                    section: t("guides-where-to-stay-tab"),
                    name: apiSections[4],
                    slug: apiSections[4] + '-' + pageData?.city_slug,
                    Icon: CampTentIcon
                },
                {
                    title: `${t("guides-gastronomy-title")} ${placeName}`,
                    section: t("guides-gastronomy-tab"),
                    name: apiSections[5],
                    slug: apiSections[5] + '-' + pageData?.city_slug,
                    Icon: PlateIcon
                }
            ]

            setSectionTitle(sectionsData[0]?.section_name);
            // document.title = sectionsData[0]?.title;
            // document.querySelector('meta[name="description"]').setAttribute('content', sectionsData[0]?.description.slice(0, 155))

            setMetaDescription(sectionsData[0]?.description.slice(0, 155));

            const btnSections = defaultSections.map(section => {
                const path = `/${t("guides")}/${city}/${section.slug}`
                return ({
                    ...section,
                    path,
                })
            })
            setSections(btnSections)
        }
    }, [city, pageData, metaDescription])


    return (
        <MainLayout>
            <Helmet>
                <title>{pageData?.title || t("guideHero-title")}</title>
                <meta
                    name="description"
                    content={metaDescription}
                    data-react-helmet="true"
                />
            </Helmet>
            <Helmet>
                <meta
                    name="robots"
                    content="index, follow"
                    data-react-helmet="true"
                />
            </Helmet>
            {
                isPageLoading ? (
                    <GuiasBlogSkeleton />
                ) : (
                    <>
                        <Hero
                            bgImage={pageData?.imagen}
                            opacity={opacities.lighter}
                            renderInputSearch={false}
                            titleHeader={t('guideHero-title')}
                            title={pageData?.city}
                            subText={sectionTitle}
                            type='span'
                        />
                        <Container>
                            <Breadcrumbs breadcrumbs={breadcrumbs} />
                            <BlogArticleButtons
                                placeName={pageData?.city}
                                activeSection={slug || t('guides-main-information')}
                                sections={sections}
                                city={city}
                            />
                            <div className='guides-content-container'>
                                <RichText text={pageData?.sections?.[0]?.htmlcontent || ''} />
                            </div>
                        </Container>
                        <DestinationGallery title={t('destinationGarllery-others')} destinations={topGuides || []} textPosition='center' />
                    </>
                )
            }
        </MainLayout>
    )
}

export default GuiasBlog
