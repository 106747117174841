import React from "react"
import { useTranslation } from 'react-i18next';
import {
    Box,
    CircularProgress,
    FormControl,
    Input as InlineInput,
    InputAdornment,
    OutlinedInput,
} from "@mui/material"
import Fuse from "fuse.js";
import PredictionsList from "../PredictionsList"
import { useClickOutsideDetector } from "../../hooks/useClickOutsideDetector"
import { ReactComponent as SearchIconFill } from "../../assets/icons/search-fill.svg"
import { getMockSuggestions, predictions } from "../../utils/data"
import { fetchPredictions, fetchSuggestions, fetchAutocompleteDestinations } from "../../utils/API/guides"
import { getHrefFromGuide, getTourPath } from "../../utils/guias"
import stringSimilarity from "string-similarity";
import { useState, useEffect } from 'react';

export const SearchInput = ({
    placeHolder = "",
    styles = {},
    source = "",
    SearchIcon = SearchIconFill,
    variant = "outlined"
}) => {
    const { t } = useTranslation(['guides', 'components']);
    const { i18n } = useTranslation();
    const [value, setValue] = React.useState("")
    const [results, setResults] = React.useState({})
    const [openModal, setOpenModal] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false)
    const [placeHolderInput, setplaceHolderInput] = React.useState(placeHolder || t('components:searchInput-placeholder'));
    const [autoComplete, setAutoComplete] = React.useState('');
    const [suggestions, setSuggestions] = useState([]);
    const wrapperRef = React.useRef(null);

    const inputVariants = {
        inline: InlineInput,
        outlined: OutlinedInput
    }
    const Input = inputVariants[variant];

    const levenshteinDistance = (a, b) => {
        const matrix = Array.from({ length: a.length + 1 }, (_, i) =>
            Array.from({ length: b.length + 1 }, (_, j) => (i === 0 ? j : j === 0 ? i : 0))
        );

        for (let i = 1; i <= a.length; i++) {
            for (let j = 1; j <= b.length; j++) {
                if (a[i - 1] === b[j - 1]) {
                    matrix[i][j] = matrix[i - 1][j - 1];
                } else {
                    matrix[i][j] = Math.min(
                        matrix[i - 1][j - 1], // Sustitución
                        matrix[i][j - 1],     // Inserción
                        matrix[i - 1][j]      // Eliminación
                    ) + 1;
                }
            }
        }

        return matrix[a.length][b.length];
    };

    const getAutocompleteSearch = async (value) => {
        setValue(value)
        if (!value) {
            setOpenModal(false);
        } else {
            setOpenModal(true)
            const baseURL = process.env.REACT_APP_BASE_URL;
            let result = await fetchAutocompleteDestinations(baseURL, value);
            if (result.length > 0) {
                setAutoComplete(result[0].name); // Parte faltante
            } else {
                setAutoComplete('');
            }
        }
    }

    const getSuggestionsSearch = async (value) => {
        setIsLoading(true);
        const baseURL = process.env.REACT_APP_BASE_URL;

        try {
            const data = await fetchSuggestions(baseURL, value);
            setIsLoading(false);

            if (!data.sugerencias || data.sugerencias.length === 0) {
                console.warn("El backend no devolvió sugerencias.");
                return {
                    sugerencias: [],
                };
            }
            const items = data.sugerencias.map(name => ({ name }));

            // Configurar Fuse.js
            const fuse = new Fuse(items, {
                keys: ["name"], // Clave para comparar
                threshold: 0.4, // Nivel de coincidencia
            });
            const filteredResults = fuse.search(value).map(result => result.item);
            return {
                sugerencias: filteredResults,
            };
        } catch (error) {
            console.error("Error al obtener las sugerencias del backend:", error);
            setIsLoading(false);

            // En caso de error, retorno sugerencias locales como respaldo
            const localItems = [
                { name: "México" },
                { name: "España" },
                { name: "Estados Unidos" },
                { name: "Francia" },
                { name: "Italia" },
            ];

            const fuse = new Fuse(localItems, {
                keys: ["name"],
                threshold: 0.4,
            });

            const localResults = fuse.search(value).map(result => result.item);
            return {
                sugerencias: localResults,
            };
        }
    };

    const getGuidesPredictions = async (value) => {
        setIsLoading(true)
        const baseURL = process.env.REACT_APP_BASE_URL
        const predictions = await fetchPredictions(baseURL, value)
        const mapPredictions = predictions.map(item => ({
            name: item.title,
            path: getTourPath(item, t('path')),
            type: (item.type) ? item.type : undefined
        }))

        const tourList = mapPredictions.filter(x => (x.type) === 'tour')
        const guideList = mapPredictions.filter(x => (x.type) === undefined)
        const citiesList = mapPredictions.filter(x => (x.type) === 'city')

        setIsLoading(false)

        if (i18n.language == 'en') {
            return {
                'Destinations': source == "guides" ? [] : citiesList,
                'Tours': source == "guides" ? [] : tourList,
                'Travel Guides': guideList,

            }
        } else {
            return {
                'Destinos': source == "guides" ? [] : citiesList,
                'Tours': source == "guides" ? [] : tourList,
                'Guías de viaje': guideList,

            }
        }
    }


    useClickOutsideDetector(wrapperRef, () => {
        setOpenModal(false);
    })

    const handleOnChange = async (evt) => {
        const { value } = evt.target;
        setValue(value)
        if (!value) {
            setOpenModal(false);
        } else {
            setOpenModal(true)
            const predictions = await getGuidesPredictions(value);
            const keys = i18n.language === 'en'
                ? ['Destinations', 'Travel Guides', 'Tours']
                : ['Destinos', 'Guías de viaje', 'Tours'];
            const hasPredictions = keys.some(key => predictions[key]?.length > 0);
            const list = hasPredictions ? predictions : await getSuggestionsSearch(value);
            setResults(list)
        }
    }


    useEffect(() => {
        getAutocompleteSearch(value);
    }, [value]);

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter' && autoComplete) {
            e.preventDefault();
            setValue(autoComplete);

            if (!autoComplete) {
                setOpenModal(false);
            } else {
                setOpenModal(true)
                const predictions = await getGuidesPredictions(autoComplete);
                const list = predictions['Destinos'].length > 0 || predictions['Guías de viaje'].length > 0 || predictions['Tours'].length > 0 ?
                    predictions :
                    await getSuggestionsSearch(autoComplete)
                setResults(list)
            }

            setAutoComplete('');
        }
    };

    const Loading = () => (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: 3,
        }}>
            <CircularProgress />
        </Box>
    )

    return (
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                maxWidth: 650,
                width: "100%",
                ...styles
            }}>
            <FormControl ref={wrapperRef} sx={{ m: 1, width: "100%", position: "relative" }}>
                <Input
                    color="primary"
                    placeholder={placeHolderInput}
                    type={"text"}
                    value={value}
                    sx={{
                        borderRadius: variant === "outlined" ? 30 : 0,
                        paddingLeft: 1,
                        paddingRight: variant === "outlined" ? 2 : 1,
                        backgroundColor: variant === "outlined" ? "#ffffff" : 'transparent',
                        input: {
                            "&::placeholder": {
                                opacity: 0.8,
                                color: variant === "outlined" ? "#666B6E" : "#40C5E8"
                            },
                        },
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon style={{
                                width: variant === "outlined" ? 36 : 30,
                                height: variant === "outlined" ? 36 : 30
                            }} />
                        </InputAdornment>
                    }
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                />
                {/* Texto de sugerencia sobrepuesto */}
                <div
                    style={{
                        position: 'absolute',
                        top: '51%',
                        left: '22px',
                        transform: 'translateY(-50%)',
                        fontSize: '16px',
                        pointerEvents: 'none',
                        zIndex: 1,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        visibility: autoComplete ? 'visible' : 'hidden',
                    }}
                >
                    {value}
                    <span style={{ color: '#ccc' }}>{value !== '' ? autoComplete.substring(value.length) : ''}</span>
                </div>
                {openModal ? (
                    <Box
                        sx={{
                            backgroundColor: "#ffffff",
                            borderRadius: 3,
                            boxShadow: 10,
                            float: "bottom",
                            maxHeight: "20rem",
                            mt: variant === "outlined" ? "4rem" : "2.5rem",
                            position: "absolute",
                            overflowY: "scroll",
                            width: "100%",
                            zIndex: 10,
                            "&::-webkit-scrollbar": {
                                width: "10px",
                            },
                            "&::-webkit-scrollbar-track": {
                                backgroundColor: "#f1f1f1",
                                borderRadius: "10px",
                                marginBlock: "15px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#888",
                                borderRadius: "10px",
                            },
                            "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "#555",
                            },
                        }}
                    >
                        {isLoading ? <Loading /> :
                            <PredictionsList
                                data={results}
                                value={value}
                                onSelect={(selectedValue) => {
                                    setValue(selectedValue);
                                    handleOnChange({ target: { value: selectedValue } }); // Trigger onChange manually
                                    setOpenModal(false);
                                }}
                            />
                        }
                    </Box>
                ) : null}
            </FormControl>
        </Box>
    )
}

export const SearchInputFilterBox = ({
    placeHolder = "",
    styles = {},
    source = "",
    SearchIcon = SearchIconFill,
    variant = "outlined",
    onSearch,
    value, // Nuevo prop para recibir el valor externo
    setValue // Prop para actualizar el valor del input
}) => {
    const { t } = useTranslation(['guides', 'components']);
    const [placeHolderInput, setplaceHolderInput] = React.useState(placeHolder || t('components:searchInput-placeholder-keyword'));
    const wrapperRef = React.useRef(null);

    const inputVariants = {
        inline: InlineInput,
        outlined: OutlinedInput
    }
    const Input = inputVariants[variant];

    const handleInputChange = (event) => {
        setValue(event.target.value);
        onSearch(event.target.value); // Envía el valor actualizado a la función de búsqueda
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                maxWidth: 650,
                width: "100%",
                ...styles
            }}>
            <FormControl ref={wrapperRef} sx={{ m: 1, width: "100%", position: "relative" }}>
                <Input
                    color="primary"
                    placeholder={placeHolderInput}
                    value={value}
                    type={"text"}
                    sx={{
                        borderRadius: variant === "outlined" ? 30 : 0,
                        paddingLeft: 1,
                        paddingRight: variant === "outlined" ? 2 : 1,
                        backgroundColor: variant === "outlined" ? "#ffffff" : 'transparent',
                        input: {
                            "&::placeholder": {
                                opacity: 0.8,
                                color: variant === "outlined" ? "#666B6E" : "#40C5E8"
                            },
                        },
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon style={{
                                width: variant === "outlined" ? 36 : 30,
                                height: variant === "outlined" ? 36 : 30
                            }} />
                        </InputAdornment>
                    }
                    onChange={handleInputChange}
                />
            </FormControl>
        </Box>
    )
}

export default SearchInput