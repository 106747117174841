import { Checkbox, FormControlLabel } from '@mui/material'
import BpRadio from '../BpRadio'
import { useTranslation } from 'react-i18next';


const Agreements = ({ handleChange, agreement1, agreement2, errors }) => {
    const { t } = useTranslation(['checkout', 'paths']);
    const AGREEMENT_LABEL_1 = t('form-agreement1');
    const AGREEMENT_LABEL_2 = t('form-agreement2');

    return (
        <>
            <FormControlLabel
                sx={{
                    '& .MuiTypography-body1': {
                        fontSize: { xs: '10px', md: '12px' },
                        color: errors.privacyPolice ? 'red' : 'inherit', // Cambia color si hay error
                    },
                    alignItems: 'flex-start'
                }}
                control={
                    <Checkbox
                        checked={agreement1}
                        onChange={(e) => handleChange(e.target.checked, 'agreement1')}
                        name={`checkbox-agreement1`}
                        inputProps={{ 'aria-label': 'special_documents' }}
                    />
                }
                label={
                    <span>
                        {AGREEMENT_LABEL_1}
                        <a
                            href={`/${t('privacy-policy')}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            {t('form-privacy-policy')}
                        </a>
                    </span>
                }
            />
            <FormControlLabel
                sx={{
                    '& .MuiTypography-body1': {
                        fontSize: { xs: '10px', md: '12px' }
                    },
                    '& .MuiFormControlLabel-labelPlacementEnd': {
                        alignItems: 'flex-start'
                    }
                }}
                value="agreement2"
                label={AGREEMENT_LABEL_2}
                control={
                    <Checkbox
                        checked={agreement2}
                        onChange={(e) => handleChange(e.target.checked, 'agreement2')}
                        name={`checkbox-agreement2`}
                        inputProps={{ 'aria-label': 'special_documents' }}
                    />
                }
            />
        </>
    )
}

export default Agreements
