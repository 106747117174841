import { Container, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel'
import IconCard from '../IconCard'

import Coins from '../../assets/images/coins.png'
import Users from '../../assets/images/users.png'
import Cancel from '../../assets/images/cancel.png'
import Edit from '../../assets/images/edit.png'



const CarouselIconCards = () => {
    const { t } = useTranslation('home');

    const cards = [
        {
            title: t('card1-title'),
            text: t('card1-text'),
            imgSource: Coins,
            altText: t('card1-altText')
        },
        {
            title: t('card2-title'),
            text: t('card2-text'),
            imgSource: Users,
            altText: t('card2-altText')
        },
        {
            title: t('card3-title'),
            text: t('card3-text'),
            imgSource: Cancel,
            altText: t('card3-altText')
        },
        {
            title: t('card4-title'),
            text: t('card4-text'),
            imgSource: Edit,
            altText: t('card4-altText')
        }
    ]
    return (
        <>
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: '3rem',
                    mb: '1rem'
                }}>
                <Typography
                    color="secondary"
                    variant="h2"
                    sx={{
                        fontSize: '32px !important',
                        fontWeight: 700,
                    }}
                >
                    {t('cards-title')}
                </Typography>
            </Container>
            <Carousel
                autoPlay={false}
                sx={{ my: '20px', display: { xs: 'block', md: 'none' } }}>
                {cards.map((card) => (
                    <IconCard key={card.title} card={card} />
                ))}
            </Carousel>
            <Grid
                spacing={1}
                container
                sx={{ my: '20px', display: { xs: 'none', md: 'flex' } }}
            >
                {cards.map((card) => (
                    <Grid key={card.title} item xs={3} sx={{ height: "fit-content" }}>
                        <IconCard card={card} />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export default CarouselIconCards
