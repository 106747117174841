import React, { useState } from 'react';
import { Button } from '@mui/material';

export default function TagButton({
    title = '',
    text,
    id = '',
    color = "primary",
    endIcon = null,
    hoverIcon = null, // Nuevo prop para el icono al hacer hover
    href = "",
    onClick = () => { },
    sx = {},
    startIcon = null,
}) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Button
            color={color}
            id={id}
            href={href}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)} // Maneja el evento hover
            onMouseLeave={() => setIsHovered(false)} // Resetea el evento hover
            sx={{
                borderRadius: "16px",
                color: "#ffffff",
                px: ".5rem",
                py: "2px",
                textTransform: "none",
                ...sx,
            }}
            variant="contained"
            startIcon={startIcon}
            endIcon={isHovered && hoverIcon ? hoverIcon : endIcon} // Cambia el ícono dinámicamente
            title={title}
        >
            {text}
        </Button>
    );
}
