/* eslint-disable no-undef */
import React
    from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Link,
    Typography,
} from '@mui/material';
import './productCard.css';
import { ReactComponent as XSquare } from '../../assets/icons/square-cancel.svg'
import { ReactComponent as Clock } from '../../assets/icons/clock.svg'
import { ReactComponent as Globe } from '../../assets/icons/globe.svg'

const ProductCard3 = ({ imageUrl, shortDescription, path, price, rate, reviews, title, duration, languages, freeCancelation }) => {
    const { t } = useTranslation(['tours', 'paths', 'components']);

    return (
        // <Link href={path} sx={{ textDecoration: "none" }}>
        <Card
            className='productcard3'
            sx={{
                width: '100%',
                maxHeight: 500,
                boxShadow: 10,
                borderRadius: 2,
                p: { xs: 0, md: ".8rem" },
            }}>
            <CardMedia
                sx={{
                    height: '10rem',
                    borderRadius: '5px',
                    borderBottomLeftRadius: { xs: 0, md: "5px" },
                    borderBottomRightRadius: { xs: 0, md: "5px" }
                }}
                image={imageUrl}
                title={title}
            />
            <Box
                className='cont-productcard3'
                sx={{
                    width: '100%',
                    px: { xs: ".8rem", md: 0 },
                    pb: { xs: ".8rem", md: 0 },
                    'display': 'flex',
                    'flex-direction': 'column'
                }}>
                <CardContent sx={{
                    px: 0
                }}>
                    <Typography
                        variant="h5"
                        component="h3"
                        sx={{ fontSize: '16px' }}
                    >{title}</Typography>
                    <Typography
                        variant='body2'
                        component='p'
                        sx={{
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                        }}
                    >{shortDescription}</Typography>
                </CardContent>
                <CardActions sx={{ p: 0 }}>
                    <Box sx={{
                        display: { xs: 'none', md: 'flex' }
                    }}>
                        {freeCancelation === 1 && (
                            <>
                                <XSquare />
                                <Typography
                                    variant='body2'
                                    component='p'
                                    color='secondary'
                                >
                                    &nbsp;{t('tours:freecancelation')}&nbsp;
                                </Typography>
                            </>
                        )}
                        <Clock />
                        <Typography
                            variant='body2'
                            component='p'
                            color='secondary'
                        >
                            &nbsp;{duration?.duration}&nbsp;{duration?.type}&nbsp;
                        </Typography>
                    </Box>
                </CardActions>
                <CardActions sx={{ p: 0 }}>
                    <Box sx={{
                        display: { xs: 'none', md: 'flex' }
                    }}>
                        <Globe />
                        <Typography
                            variant='body2'
                            component='p'
                            color='secondary'
                        >
                            {languages.map((item, index) => {
                                return index == 0 ? item.language : ', ' + item.language;
                            })}
                        </Typography>
                    </Box>
                </CardActions>
                <CardActions sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    p: 0,
                    marginTop: 'auto'
                }}>

                    <Box sx={{
                        alignItems: "center",
                        display: 'flex',
                        flexDirection: "row",
                        gap: { xs: 1, md: "2px" },
                    }}>
                        <Box
                            sx={{
                                px: ".8rem",
                                py: "2px",
                                lineHeight: 'normal',
                                borderRadius: 1,
                                bgcolor: 'secondary.main',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                variant="h6"
                                component="span"
                                color="primary.contrastText"
                                sx={{
                                    fontSize: '16px',
                                    fontWeight: 'bold'
                                }}>
                                {rate}
                            </Typography>
                        </Box>

                        <Typography
                            fontFamily={'Poppins'}
                            sx={{
                                lineHeight: '1.9',
                                fontSize: { xs: '12px', md: '14px' }
                            }}>
                            {`(${reviews} Reviews)`}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            color="secondary"
                            sx={{
                                fontSize: { xs: '14px', md: '18px' },
                                fontWeight: { xs: 600, md: 700 }
                            }}
                        >
                            {t('from')}<br />
                            {`${price?.from} €`}
                        </Typography>
                        <Typography
                            color="grayText"
                            sx={{
                                textAlign: 'right',
                                fontSize: { xs: '10px', md: '12px' },
                                fontWeight: 400
                            }}>
                            {t('IVAincluded')}
                        </Typography>
                    </Box>
                </CardActions>
            </Box>
        </Card>
        // </Link >
    )
}

export default ProductCard3
