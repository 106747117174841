import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { Height } from "@mui/icons-material";

const StyledSlider = styled(Slider)({
  color: '#a1a1a1',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
    backgroundColor: '#223F7F',
  },
  '&.MuiSlider-root': {
    marginBottom: '0px',
  },
  '& .MuiSlider-mark': {
    height: '0px',
  },
  '& .MuiSlider-thumb': {
    height: 18,
    width: 18,
    backgroundColor: '#fff',
    borderRadius: '6px',
    border: '6px solid #40C5E8',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#223F7F',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
  '& .MuiSlider-markLabel': {
    transform: 'translateY(-40px)',
  },
  // Ajuste para el label del marcador izquierdo (valor mínimo)
  '& .MuiSlider-markLabel[data-index="0"]': {
    transform: 'translate(0px, -40px)', // Mueve 10px a la derecha
  },
  // Ajuste para el label del marcador derecho (valor máximo)
  '& .MuiSlider-markLabel[data-index="1"]': {
    transform: 'translate(-25px, -40px)', // Mueve 10px a la izquierda
  },

});

const RangeSlider = ({ title, marks, min, max, ariaLabel1, ariaLabel2, valueSlider, handleChange }) => (
  <Accordion defaultExpanded sx={{ mb: '25px', borderRadius: '8px' }}>
    <AccordionSummary
      expandIcon={<ArrowDropDownIcon />}
      aria-controls={`${title}-content`}
      id={`${title}-header`}
    >
      <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ py: 0 }} >
      <StyledSlider
        valueLabelDisplay="auto"
        getAriaLabel={(index) => (index === 0 ? ariaLabel1 : ariaLabel2)}
        value={valueSlider}
        marks={marks}
        onChange={handleChange}
        max={max}
        min={min}
      />
    </AccordionDetails>
  </Accordion>
)
export default RangeSlider
