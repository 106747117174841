
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";
const normalizeDays = (day) => (day === 0 ? 7 : day);
const rotateDay = (day) => (day === 0 ? 1 : day === 6 ? 0 : day + 1);

const Schedule = ({ title, availabilityHours = [] }) => {

    return (
        <Accordion defaultExpanded sx={{ mb: '25px', borderRadius: '8px' }}>
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls={`${title}-content`}
                id={`${title}-header`}
            >
                <Typography>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ height: 'auto' }}>
                <FormControl>
                    {/* <FormLabel id="schedule">Horario</FormLabel> */}
                    <RadioGroup
                        row
                        aria-labelledby="schedule"
                        name="row-radio-buttons-group"
                    >
                        {availabilityHours[0].map((hour) => (
                            <FormControlLabel
                                key={hour.hour}
                                control={<Radio />}
                                label={hour.hour}
                                value={hour.hour}
                            />
                        ))}
                        {/* <FormControlLabel value="09:00" control={<Radio />} label="09:00" />
                        <FormControlLabel value="11:00" control={<Radio />} label="11:00" />
                        <FormControlLabel value="14:00" control={<Radio />} label="14:00" /> */}
                    </RadioGroup>
                </FormControl>
            </AccordionDetails>
        </Accordion>
    )
}
export default Schedule;
