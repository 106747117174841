import React from 'react';
import './locales/i18n'; // Importar configuración de i18next
import { createTheme, ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TimeAgo from 'javascript-time-ago';
import es from 'javascript-time-ago/locale/es';
import { useTranslation } from 'react-i18next';
import RouterComponent from './router';
import theme from './theme';

function App() {
    const customTheme = createTheme(theme);
    TimeAgo.addDefaultLocale(es);
    TimeAgo.addLocale(es);

    const { t } = useTranslation();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={customTheme}>
                <RouterComponent t={t} />
            </ThemeProvider>
        </LocalizationProvider>
    );
}

export default App;
