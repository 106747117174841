import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Card,
    CardMedia,
    CardContent,
    Link,
    Typography,
    CardActions,
    Box,
    Button
} from '@mui/material';
import { ReactComponent as XSquare } from '../../assets/icons/square-cancel.svg'
import { ReactComponent as Clock } from '../../assets/icons/clock.svg'
import { ReactComponent as Globe } from '../../assets/icons/globe.svg'
import './styles.css';

const ProductCard2 = ({ images, price, country, rate, reviewsTotal, name, description, path, duration, freeCancelation, languages, shortDescription }) => {

    const { t } = useTranslation(['tours', 'paths', 'components']);
    const [reviewStatus, setReviewStatus] = React.useState("");
    const [rating, setRaiting] = React.useState(7);


    useEffect(() => {
        if (reviewsTotal >= 0 && reviewsTotal < 250) {
            setReviewStatus(`${t('ReviewStatus-1')} `);
            setRaiting(7);
        } else if (reviewsTotal >= 250 && reviewsTotal <= 1000) {
            setReviewStatus(`${t('ReviewStatus-2')} `);
            setRaiting(9);
        } else if (reviewsTotal > 1000) {
            setReviewStatus(`${t('ReviewStatus-3')} `);
            setRaiting(10);
        } else {
            setReviewStatus(`${t('ReviewStatus-0')}`);
        }
    }, [reviewsTotal])



    return (
        // <Link href={path} sx={{ textDecoration: "none" }}>
        <Card
            id="product-card-2"
            className='product-card'
            sx={{
                display: { xs: 'block', md: 'flex' },
                height: { xs: 'auto', md: 'auto' },
                borderRadius: '10px',
                pr: { xs: 0, md: '10px' },
                my: '15px'
            }}>
            <CardMedia
                sx={{
                    width: { xs: '100%', md: '200px' },
                    height: { xs: '140px', md: 'auto' }
                }}
                image={images}
                title={name}
            />
            <Box
                sx={{
                    width: '100%',
                    display: { xs: 'block', md: 'flex' },
                    flexDirection: 'column'
                }}
            >
                <CardContent
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography
                        sx={{ fontSize: '18px!important' }}
                        variant="subtitle1"
                        component="h3"
                    >{name}</Typography>
                    <Button
                        sx={{
                            fontSize: { xs: '10px', md: '12px' },
                            py: '2px',
                            xs: 'fit-content',
                            height: '30px',
                            minWidth: '100px'
                        }}
                        variant="contained"
                    >{t('tours:seemore')}</Button>
                </CardContent>
                <CardContent sx={{ pt: '0px' }}>
                    <Typography
                        className='shortDescription'
                        sx={{
                            display: { xs: 'none', md: '-webkit-box' },
                            fontSize: '13px',
                        }}
                        variant='body2'
                        component='p'
                    >{shortDescription}</Typography>
                    <Typography
                        className='shortDescription'
                        sx={{
                            display: { xs: 'block', md: 'none' },
                            fontSize: '13px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                        variant='body2'
                        component='p'
                    >{shortDescription}</Typography>
                </CardContent>
                <CardActions sx={{ pt: 0, pl: 2 }}>
                    <Box sx={{
                        display: { xs: 'none', md: 'flex' }
                    }}>
                        {freeCancelation === 1 && (
                            <>
                                <XSquare />
                                <Typography
                                    variant='body2'
                                    component='p'
                                    color='secondary'
                                >
                                    &nbsp;{t('tours:freecancelation')}&nbsp;
                                </Typography>
                            </>
                        )}
                        <Clock />
                        <Typography
                            variant='body2'
                            component='p'
                            color='secondary'
                        >&nbsp;{duration?.duration}&nbsp;{duration?.type}&nbsp;</Typography>
                        <Globe />
                        <Typography
                            variant='body2'
                            component='p'
                            color='secondary'
                        >
                            {languages.map((item, index) => {
                                return index == 0 ? item.language : ', ' + item.language;
                            })}
                        </Typography>
                    </Box>
                    <Box sx={{
                        display: { xs: 'flex', md: 'none' },
                        ml: '0px!important'
                    }}>
                        <XSquare />
                        <Typography
                            variant='body2'
                            component='p'
                            color='primary'
                        >&nbsp;{t('tours:freecancelation')}&nbsp;</Typography>
                    </Box>
                </CardActions>
                <CardActions sx={{ py: 0, display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box
                            sx={{
                                width: 40,
                                height: 28,
                                borderRadius: 2,
                                bgcolor: 'secondary.main',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginLeft: 0.5,
                            }}>
                            <Typography
                                variant="span"
                                color="primary.contrastText"
                                sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                {rating}
                            </Typography>
                        </Box>
                        <Typography
                            mb={2}
                            pl={1}
                            fontFamily={'Poppins'}
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                lineHeight: '1.9'
                            }}>
                            {`${reviewStatus} `}
                        </Typography>
                        &nbsp;
                        <Typography
                            mb={2}
                            fontFamily={'Poppins'}
                            sx={{
                                fontSize: { xs: '14px', md: '16px' },
                                lineHeight: '1.9'
                            }}>
                            {`(${reviewsTotal} Reviews)`}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            color="secondary"
                            sx={{
                                fontSize: { xs: '14px', md: '18px' },
                                fontWeight: { xs: 600, md: 700 }
                            }}>
                            {`${t('from')} ${price?.from} €`}
                        </Typography>
                        <Typography
                            color="grayText"
                            sx={{
                                textAlign: 'right',
                                fontSize: { xs: '10px', md: '12px' },
                                fontWeight: 400
                            }}>
                            {country.slug == 'espana' ? t('IVAincluded') : ''}
                        </Typography>
                    </Box>
                </CardActions>
            </Box>
        </Card>
        // </Link>
    )
};

export default ProductCard2;
