import React from 'react'
import { useTranslation } from 'react-i18next';
import './styles.css'

const BlogArticleButtons = ({
    placeName,
    sections,
    activeSection,
    city
}) => {
    const { t } = useTranslation('guides');
    if (!sections?.length) return null;

    const isOnSomeButtonPage = sections.some(
        (btn) => btn.name === activeSection
    ) || 'guia-'

    if (!placeName || !isOnSomeButtonPage) return null

    return (
        <ul className="btn-list">
            {sections.map(({ title, path, Icon, name, section }, index) => (
                <li key={`${title}${index}`} className="list-item_article">
                    <a
                        href={path}
                        title={`${t('guideHero-title')} ${city} - ${section}`}
                        className="list-item_link"
                        style={{
                            backgroundColor:
                                activeSection.includes(name) ? '#223F7F' : '#40C5E8'
                        }}>
                        <Icon
                            style={{ marginBottom: '.5rem' }}
                            height={36}
                            width={36}
                            className="list-icon"
                        />
                        <span>{title}</span>
                    </a>
                </li>
            ))}
        </ul>
    )
}

export default BlogArticleButtons
