import { Typography } from '@mui/material'
import './Avatar.css'

const AvatarCard = ({
    imageUrl = '',
    altText = '',
    avatarName = '',
    title = ''
}) => {
    const image = require(`../../assets/images/${imageUrl}`)

    return (
        <div className="avatar-container">
            <img src={image} alt={altText} className="avatar-image" />
            <Typography
                color="secondary.main"
                variant="title2"
                component="h3"
                fontWeight={600}
                sx={{ marginTop: '1rem' }}>
                {avatarName}
            </Typography>
            <Typography color="primary.main" variant="subtitle3">
                {title}
            </Typography>
        </div>
    )
}

export default AvatarCard
