import React from 'react'
import { useTranslation } from 'react-i18next';

import { Box, Divider, Link, Typography } from '@mui/material'
import { ReactComponent as XSquare } from '../../../assets/icons/x-square.svg'
import { ReactComponent as Lock } from '../../../assets/icons/lock.svg'
import { ReactComponent as Clock } from '../../../assets/icons/clock.svg'
import SimpleProductCard from '../Step1/simpleProductCard'
import ResumenAccordion from '../resumenAccordion'
import CuponLink from '../CuponLink'
import { useSelector } from 'react-redux'

const Resumen = ({ cartTours }) => {
    const { t } = useTranslation(['checkout', 'components']);
    const products = useSelector(state => state.cart.checkout.tours)
    return (
        <>
            <Box
                key="resumen"
                sx={{
                    bgcolor: '#FFFFFF',
                    p: '15px',
                    mb: '15px',
                    borderRadius: '8px',
                    width: '100%'
                }}>
                {products.map((item) => (
                    <SimpleProductCard key={item.name} data={item} />
                ))}
                <ResumenAccordion />
                <Divider
                    aria-hidden="true"
                    sx={{
                        height: 1,
                        width: '100%',
                        mt: '15px'
                    }}
                />
                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'start',
                        p: '10px',
                        my: '20px'
                    }}>
                    <Box>
                        <XSquare height={25} width={25} />
                    </Box>
                    <Box sx={{ pl: '10px' }}>
                        <Typography
                            color="secondary"
                            variant="h5"
                            component="h5"
                            sx={{
                                fontSize: '16px'
                            }}>
                            {t('free-cancellation')}
                        </Typography>
                        <Typography
                            variant="body2"
                            component="p"
                            sx={{
                                color: '#666B6E',
                                fontSize: '14px'
                            }}>
                            {t('free-cancellation-text')}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'start',
                        p: '10px',
                        my: '20px'
                    }}>
                    <Box>
                        <Lock height={25} width={25} />
                    </Box>
                    <Box sx={{ pl: '10px' }}>
                        <Typography
                            color="secondary"
                            variant="h5"
                            component="h5"
                            sx={{
                                fontSize: '16px'
                            }}>
                            {t('secure-payment')}
                        </Typography>
                        <Typography
                            variant="body2"
                            component="p"
                            sx={{
                                color: '#666B6E',
                                fontSize: '14px'
                            }}>
                            {t('secure-payment-text')}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: 'auto',
                        borderRadius: '8px',
                        display: 'flex',
                        justifyContent: 'start',
                        p: '10px',
                        my: '20px'
                    }}>
                    <Box>
                        <Clock height={25} width={25} />
                    </Box>
                    <Box sx={{ pl: '10px' }}>
                        <Typography
                            color="secondary"
                            variant="h5"
                            component="h5"
                            sx={{
                                fontSize: '16px'
                            }}>
                            {t('missing-something')}
                        </Typography>
                        <Typography
                            variant="body2"
                            component="p"
                            sx={{
                                color: '#666B6E',
                                fontSize: '14px'
                            }}>
                            {t('missing-something-text')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {/*<CuponLink />*/}
        </>
    )
}

export default Resumen
