import { useTranslation } from 'react-i18next';
import { Box, Container, Typography } from '@mui/material'
import { ReactComponent as BusImage } from '../../../assets/images/bus.svg'
import bgImg from '../../../assets/images/trekzy-blue-background.png'
import './styles.css'
import { useScreenDetector } from '../../../hooks/useScreenDetector'

const SubscribeBanner = () => {
    const { t } = useTranslation(['aboutus']);
    const { isMobile } = useScreenDetector()
    const logoSize = isMobile ? 200 : 300

    return (
        <div
            className="subscription-container"
            style={{ '--img': `url(${bgImg})` }}>
            <Container
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: { xs: 'center', md: 'space-between' },
                    marginBottom: { xs: 4, md: -3 },
                    marginTop: { xs: 0, md: -3 }
                }}>
                <BusImage height={logoSize} width={logoSize} />
                <Box
                    sx={{
                        textAlign: { xs: 'center', md: 'left' }
                    }}>
                    <Typography
                        color="primary.contrastText"
                        component="h2"
                        sx={{
                            fontSize: { xs: 24, md: 28 },
                            fontWeight: 700,
                            lineHeight: 1.2,
                            mb: 2
                        }}>
                        {t('subscribebanner-title')}
                    </Typography>
                    <Typography
                        color="primary.contrastText"
                        sx={{
                            fontSize: { xs: 16, md: 20 },
                            lineHeight: 1.2,
                            mb: 2
                        }}>
                        {t('subscribebanner-text')}
                    </Typography>
                    <div className="input-container_sub">
                        <input
                            className="email-input"
                            placeholder={t('subscribebanner-placeholder')}
                            type="text"
                        />
                        <button className="send-btn">{t('subscribebanner-button')}</button>
                    </div>
                </Box>
            </Container>
        </div>
    )
}

export default SubscribeBanner
