import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import Tabs from '../CardsTab/Tabs';
import BlurImageWrapper, { opacities, orientations } from '../BlurImageWrapper';
import {
    SearchInputFilterBox

} from '../SearchInput';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { useScreenDetector } from '../../hooks/useScreenDetector';
import './styles.css';
import TagButton from '../TagButton';
import { getHrefFromGuide } from '../../utils/guias';

const normalizeText = (text) => {
    console.log(text);
    return text
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
};

const GuidesTabs = ({ tabsData }) => {
    const { t } = useTranslation('guides');
    const [activeTab, setActiveTab] = React.useState(0);
    const [rowExpanded, setRowExpanded] = React.useState(null);
    const [searchValue, setSearchValue] = React.useState("");
    const [searchTerm, setSearchTerm] = React.useState('');
    const { isMobile } = useScreenDetector();

    if (!tabsData?.length) return null;

    const allGuides = tabsData
        .map((element) => element.countries)
        .filter(Boolean)
        .reduce((a, b) => [...a, ...b]);

    const normalizedSearchTerm = normalizeText(searchTerm);
    const filteredTabs = [
        {
            name: t("guideTabs-tab-all"),
            countries: allGuides.filter((country) => {
                const matchesCountry = normalizeText(country.name).includes(normalizedSearchTerm);
                const matchesCity = country.cities.some((city) =>
                    normalizeText(city.name).includes(normalizedSearchTerm)
                );
                return matchesCountry || matchesCity;
            }),
        },
        ...tabsData
            .map((tab) => ({
                ...tab,
                countries: tab.countries.filter((country) => {
                    const matchesCountry = normalizeText(country.name).includes(normalizedSearchTerm);
                    const matchesCity = country.cities.some((city) =>
                        normalizeText(city.name).includes(normalizedSearchTerm)
                    );
                    return matchesCountry || matchesCity;
                }),
            }))
            .filter((tab) => tab.countries.length > 0),
    ];

    const onChangeTab = (index) => (evt, tabIndex) => {
        setRowExpanded(null);
        const tab = index !== undefined ? index : tabIndex;
        setActiveTab(tab);
    };

    const onExpandRow = (index) => {
        if (index === rowExpanded) {
            setRowExpanded(null);
        } else {
            setRowExpanded(index);
        }
    };

    const NoResultsContainer = () => (
        <Box sx={{ py: '3rem', px: '2rem', textAlign: 'center' }}>
            <Typography>{t("guideTabs-search-noresults")}</Typography>
        </Box>
    );

    const TagsBox = ({ tags, index }) => (
        <Box
            sx={{
                display: {
                    xs: rowExpanded === index ? 'flex' : 'none',
                    md: 'flex',
                },
                flexWrap: 'wrap',
                gap: '1rem',
                py: '1rem',
                px: { xs: '.5rem', md: '1.5rem' },
            }}
        >
            {tags.map((tag, index) => {
                const [guide] = tag.destinationGuides;
                return (
                    <TagButton
                        key={`${tag.name}${index}`}
                        text={tag.name}
                        href={getHrefFromGuide(guide, t('path'))}
                        sx={{ height: '2rem' }}
                        title={`${t("guideTabs-search-tagbutton")} ${tag.name}`}
                    />
                );
            })}
        </Box>
    );

    const RowCard = ({ country, index }) => {
        const imageURl = country?.cities[0]?.destinationGuides[0]?.imagen;
        return (
            <BlurImageWrapper
                imageUrl={imageURl}
                opacity={opacities.darker}
                orientation={orientations.toTopLeft}
                styles={{
                    borderRadius: `
                        10px
                        ${isMobile ? '10px' : '0'}
                        ${rowExpanded === index || !isMobile ? '0' : '10px'}
                        ${rowExpanded === index ? '0' : '10px'}`,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        py: '1.2rem',
                        px: '1rem',
                    }}
                >
                    <Box>
                        <Typography
                            color="primary.contrastText"
                            sx={{
                                fontFamily: 'Inter',
                                fontSize: 24,
                                fontWeight: 700,
                            }}
                            component="h3"
                        >
                            {country.name}
                        </Typography>
                        <Typography
                            color="primary.contrastText"
                            sx={{
                                fontFamily: 'Inter',
                                fontSize: 16,
                            }}
                        >
                            {`${country.cities.length} ${t("guideTabs-destinations")}`}
                        </Typography>
                    </Box>
                    <Button
                        onClick={() => onExpandRow(index)}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                            bottom: -20,
                            right: -20,
                        }}
                    >
                        {rowExpanded === index ? (
                            <KeyboardArrowUp sx={{ color: 'white' }} />
                        ) : (
                            <KeyboardArrowDown sx={{ color: 'white' }} />
                        )}
                    </Button>
                </Box>
            </BlurImageWrapper>
        );
    };

    return (
        <Container sx={{ my: '4rem' }}>
            <Typography
                color="secondary"
                variant="subtitle"
                sx={{
                    fontWeight: '700',
                    textAlign: { xs: 'center', md: 'left' },
                }}
                component="h2"
            >
                {t("guideTabs-title")}
            </Typography>
            <Box sx={{ mt: '1.5rem' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                >
                    <Box
                        sx={{
                            order: { xs: 2, md: 1 },
                            width: { xs: '100%', md: '77%' },
                        }}
                    >
                        <Tabs activeTab={activeTab} tabs={filteredTabs} onChangeTab={onChangeTab} />
                    </Box>
                    <Box
                        sx={{
                            mb: { xs: '1rem', md: 0 },
                            order: { xs: 1, md: 2 },
                            px: { xs: '3rem', md: 0 },
                            width: { xs: '100%', md: '23%' },
                        }}
                    >
                        <SearchInputFilterBox
                            placeHolder={t("guideTabs-search-placeholder")}
                            SearchIcon={SearchIcon}
                            source="guides"
                            variant="inline"
                            onSearch={setSearchTerm}
                            value={searchValue}
                            setValue={setSearchValue}
                        />
                    </Box>
                </Box>
                <ul className="list_container">
                    {filteredTabs[activeTab]?.countries?.length ? (
                        <>
                            {filteredTabs[activeTab].countries.map((country, index) =>
                                country?.cities?.length ? (
                                    <li className="list-item" key={country.name}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: { xs: 'column', md: 'row' },
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    height: '100',
                                                    width: { xs: '100%', md: '20%' },
                                                }}
                                            >
                                                <RowCard country={country} index={index} />
                                            </Box>
                                            <TagsBox index={index} tags={country.cities} />
                                        </Box>
                                    </li>
                                ) : null
                            )}
                        </>
                    ) : (
                        <NoResultsContainer />
                    )}
                </ul>
            </Box>
        </Container>
    );
};

export default GuidesTabs;
