import React from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, Typography } from '@mui/material'
import { CarouselProvider, DotGroup, Slider, Slide } from 'pure-react-carousel'

import BlurImageWrapper, { opacities } from '../../BlurImageWrapper'
import AvatarCard from '../../AvatarCard'
import { useScreenDetector } from '../../../hooks/useScreenDetector'

import trekzyHeroLight from '../../../assets/images/bg-light-t-2.png'

const DesktopTeamSection = ({ children }) => {
    const { t } = useTranslation(['aboutus', 'paths', 'components']);

    return (
        <BlurImageWrapper imageUrl={trekzyHeroLight} opacity={opacities.none}>
            <Container
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    py: '4rem'
                }}>
                <Typography
                    color="secondary.main"
                    variant="h2"
                    sx={{ mb: '1rem', fontWeight: 'bold' }}>
                    {t('ourteam-title')}
                </Typography>
                <Typography
                    variant="body1"
                    color="graytext.main"
                    sx={{
                        textAlign: 'center',
                        maxWidth: 1024
                    }}>
                    {t('ourteam-subtitle')}
                </Typography>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        columnGap: '3rem',
                        mt: '1rem'
                    }}>
                    {children}
                </Box>
            </Container>
        </BlurImageWrapper>
    )
}

const MobileTeamSection = ({ children }) => {
    const { t } = useTranslation(['aboutus', 'paths', 'components']);

    return (
        <Box>
            <Container
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    mb: '2rem'
                }}>
                <Typography
                    color="secondary.main"
                    variant="subtitle"
                    sx={{ mb: '1rem', fontWeight: 'bold' }}>
                    {t('ourteam-title')}
                </Typography>
                <Typography
                    variant="body1"
                    color="graytext.main"
                    sx={{
                        textAlign: 'center',
                        maxWidth: 1024
                    }}>
                    {t('ourteam-subtitle')}
                </Typography>
            </Container>
            <CarouselProvider
                naturalSlideWidth={120}
                naturalSlideHeight={125}
                totalSlides={children.length}
                visibleSlides={1}
                currentSlide={Math.floor(children.length / 2) - 1}>
                <Slider>
                    {children.map((Child, index) => (
                        <Slide index={index} key={index}>
                            {Child}
                        </Slide>
                    ))}
                </Slider>
            </CarouselProvider>
        </Box>
    )
}

const TeamSection = () => {
    const { t } = useTranslation(['aboutus']);
    const { isMobile } = useScreenDetector()
    const Wrapper = isMobile ? MobileTeamSection : DesktopTeamSection
    return (
        <Wrapper>
            <AvatarCard
                imageUrl="Soraya.jpg"
                height={isMobile ? '200px' : '300px'}
                width={isMobile ? '180px' : '300px'}
                avatarName="Soraya Plaza"
                title={t('role-Projects')}
            />
            <AvatarCard
                imageUrl="MariaLaura.jpeg"
                height={isMobile ? '200px' : '300px'}
                width={isMobile ? '180px' : '300px'}
                avatarName="Maria Laura"
                title={t('role-Marketing')}
            />
            <AvatarCard
                imageUrl="Maria.jpg"
                height={isMobile ? '200px' : '300px'}
                width={isMobile ? '180px' : '300px'}
                avatarName="María Cordero"
                title={t('role-HR')}
            />
            <AvatarCard
                imageUrl="Valeria.jpg"
                height={isMobile ? '200px' : '300px'}
                width={isMobile ? '180px' : '300px'}
                avatarName="Valeria de Magistris"
                title={t('role-Operations')}
            />
            <AvatarCard
                imageUrl="Aaron.jpeg"
                height={isMobile ? '200px' : '300px'}
                width={isMobile ? '180px' : '300px'}
                avatarName="Aaron del Río"
                title={t('role-Administration')}
            />
            <AvatarCard
                imageUrl="LorenaQuintanilla.jpg"
                height={isMobile ? '200px' : '300px'}
                width={isMobile ? '180px' : '300px'}
                avatarName="Lorena Quintanilla"
                title={t('role-Suppliers')}
            />
        </Wrapper>
    )
}

export default TeamSection
