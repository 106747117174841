import React from 'react'
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import Hero from '../../components/Hero'
import GuideCards from '../../components/GuideCards'
import DestinationGallery from '../../components/DestinationGallery'
import GuidesTabs from '../../components/GuidesTabs'
import MainLayout from '../../components/MainLayout'
import useFetchTopGuides from '../../hooks/API/Guides/useFetchTopGuides'
import useFetchGuidesByRegion from '../../hooks/API/Guides/useFetchGuidesByRegion'
import heroImage from '../../assets/images/trekzy-blue-background.png'

const GuiasDeViaje = () => {
    const { t } = useTranslation('guides');
    const { topGuides } = useFetchTopGuides()
    const { guides } = useFetchGuidesByRegion()
    return (
        <MainLayout>
            <Helmet>
                <title>{t("page")}</title>
                <meta
                    name="description"
                    content={t("metadescription")}
                    data-react-helmet="true"
                />
            </Helmet>
            <Helmet>
                <meta
                    name="robots"
                    content="index, follow"
                    data-react-helmet="true"
                />
            </Helmet>
            <Hero
                bgImage={heroImage}
                placeHolderText={t("hero-placeHolderText")}
                title={t("hero-title")}
                text={t("hero-text")}
                searchSource="guides"
            />
            <GuideCards />
            <DestinationGallery title={t("destinationGarllery-title")} destinations={topGuides || []} />
            <GuidesTabs tabsData={guides} />
        </MainLayout>
    )
}

export default GuiasDeViaje
