import React, { act, useEffect } from "react"
import { useTranslation } from 'react-i18next';
import Hero from "../../components/Hero";
import { Helmet } from "react-helmet";
import heroImage from "../../assets/images/red-pin-hero.png"
import Breadcrumbs from "../../components/Breadcrumbs";
import { Container } from "@mui/material";
import CardsGrid from "../../components/CardsGrid";
import MainLayout from "../../components/MainLayout";
import { opacities } from "../../components/BlurImageWrapper";
import { cards } from "./mockData";
import useFetchCountries from "../../hooks/API/Destinations/useFetchCountries";

/**
 * 
  {
        name: "Italia",
        cities: "12",
        activities: 10000,
        path: "destinos/italia",
        image: require("../../assets/images/rome.png")
    }
 */
const DestinationsPrincipal = () => {
    const { t } = useTranslation('destinations');

    const { countries: apiCountries, isLoading } = useFetchCountries();
    const [gridCards, setGridCards] = React.useState([]);

    useEffect(() => {
        if (isLoading) return;

        if (apiCountries?.countries) {
            const gridCards = apiCountries?.countries.map(item => {
                return {
                    name: item.name,
                    cities: item.total_cities,
                    activities: item.total_tours,
                    path: `${t('path')}/${item.slug}`,
                    image: item.image
                }
            });


            setGridCards(gridCards)
        }




    }, [apiCountries, isLoading])


    return (
        <MainLayout>
            <Helmet>
                <title>{t('page')}</title>
                <meta
                    name="description"
                    content={t('metadescription')}
                    data-react-helmet="true"
                />
            </Helmet>
            <Helmet>
                <meta
                    name="robots"
                    content="index, follow"
                    data-react-helmet="true"
                />
            </Helmet>
            <Hero
                bgImage={heroImage}
                opacity={opacities.lighter}
                renderInputSearch={false}
                text={t('hero-text')}
                title={t('hero-title')}
            />
            <Container>
                <Breadcrumbs breadcrumbs={[{
                    name: t('hero-title'),
                    path: t('path')
                }]} />
                {gridCards && <CardsGrid type={t('CardsGrid-type-countries')} cards={gridCards} />}
            </Container>
        </MainLayout>
    )
}

export default DestinationsPrincipal;
