import React from "react";
import { useTranslation } from 'react-i18next';
import { Box, Typography, } from "@mui/material"
import { ReactComponent as VisaLogo } from "../../assets/icons/visa-white.svg";
import { ReactComponent as MarsterCardLogo } from "../../assets/icons/mastercard-white.svg";
import { ReactComponent as StripeLogo } from "../../assets/icons/stripe.-white.svg";

const PayMethodsSection = () => {
    const { t } = useTranslation(['footer']);

    return (
        <Box sx={{ mb: "2rem" }}>
            <Typography sx={{
                color: "primary.contrastText",
                fontSize: { xs: "12px", md: "14px" },
                textAlign: "left",
                my: "1rem",
            }}>
                {t("payMethods-title")}
            </Typography>
            <Box sx={{
                display: "flex",
                justifyContent: "start",
                px: 0,
                gap: "1rem"
            }}>
                <VisaLogo />
                <MarsterCardLogo />
                <StripeLogo />
            </Box>
        </Box>

    )
}

export default PayMethodsSection;
