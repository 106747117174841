import { Box, Link, Typography } from '@mui/material'
import { Helmet } from "react-helmet";
import MainLayout from '../../components/MainLayout'
import StaticPagesWrapper from '../../components/StaticPagesWrapper'
import ListItemHeader from '../../components/StaticPagesWrapper/ListItemHeader'

const CancelationPolicies = () => (
    <MainLayout>
        <Helmet>
            <title>Política general de cancelación y reembolso de reservaciones - ToursPrivados</title>
            <meta
                name="description"
                content="Política general de cancelación y reembolso de reservaciones."
                data-react-helmet="true"
            />
        </Helmet>
        <StaticPagesWrapper title="Trekzy">
            <Box mb={8}>
                <ListItemHeader styles={{ padding: 0 }}>
                    Política general de cancelación y reembolso de reservaciones
                </ListItemHeader>
                <Typography mb={2}>
                    Si usted es (i) una persona física o persona moral; o (ii)
                    una agencia de viajes o tours (<strong>“Agencia”</strong>) (
                    <strong>“Usted”</strong>) y ha realizado (por su propio
                    derecho o en favor de terceras personas) una o más reservas
                    de cualquier servicio ofrecido a través del sitio web:{' '}
                    <Link href="https://trekzy.com" target="_blank">
                        https://trekzy.com
                    </Link>{' '}
                    o{' '}
                    <Link href="https://trekzy.com" target="_blank">
                        https://trekzy.com
                    </Link>{' '}
                    (<strong>“Sitio”</strong>)operado y/o propiedad de Tour
                    Review, S.L. (<strong>“TREKZY”</strong> según el destino del
                    servicio contratado a través del Sitio) Usted acepta
                    expresamente sujetarse a esta Política General de
                    Cancelación y Reembolso de Reservaciones (la{' '}
                    <strong>“Política”</strong>) una vez que la reserva de
                    cualquier servicio contratado a través del Sitio ha sido
                    confirmada por TREKZY (una{' '}
                    <strong>“Reserva Confirmada”</strong>) por lo que le pedimos
                    leer de manera detenida esta Política previamente a realizar
                    cualquier transacción a través del Sitio.
                </Typography>
                <ListItemHeader>
                    1. Cancelaciones y modificaciones
                </ListItemHeader>
                <Typography mb={2}>
                    Usted podrá solicitar a TREKZY, modificaciones,
                    cancelaciones o especificaciones adicionales de su Reserva
                    Confirmada reconociendo que toda resolución a su solicitud
                    de cancelación, modificación o especificaciones adicionales,
                    dependerá estrictamente de la disponibilidad del servicio
                    que Usted haya contratado a través del Sitio por lo que
                    TREKZY establece como regla general un plazo de 48 (cuarenta
                    y ocho) horas de anticipación como mínimo para que Usted
                    tenga derecho a que se le reembolse hasta el 100% (cien por
                    ciento), salvo excepciones aplicando posibles restricciones
                    las cuales estarán debidamente descritas en el tour y/o será
                    informado por el proveedor en su momento de confirmación
                    según la jurisdicción del servicio contratado.
                    <ul>
                        <li>
                            Ninguna solicitud de cancelación hecha con menos de
                            48 (cuarenta y ocho) horas previas a la fecha de la
                            Reserva Confirmada será reembolsable.
                        </li>
                        <li>
                            Si el servicio (tour, traslado u otro) se ha
                            reservado con un código promocional o con descuento
                            especial aplicado, no procederá el reembolso al
                            100%, aun habiéndose cancelado el mismo en los
                            tiempos estipulados. En estos casos, el porcentaje
                            máximo de reembolso será de 80%.
                        </li>
                        <li>
                            Los descuentos especiales y/o códigos de promoción
                            serán aplicables únicamente durante las fechas
                            establecidas para el descuento en su Reserva
                            Confirmada. Dichos descuentos no serán retroactivos
                            en reservaciones realizadas en periodos anteriores a
                            la promoción y/o en las que Usted omita ingresar el
                            código de promoción en el campo correspondiente al
                            momento de la reserva.
                        </li>
                        <li>
                            En cancelaciones de Reservas Confirmadas motivadas
                            con el fin de recibir un descuento publicado
                            posterior a la compra original, se aplicará una
                            penalización del 20% (veinte por ciento).
                        </li>
                        <li>
                            Los servicios especificados en una Reserva
                            Confirmada constituyen la totalidad del mismo por lo
                            que no habrá devoluciones parciales por servicios o
                            consumos no realizados durante el mismo.
                        </li>
                        <li>
                            Para Reservas Confirmadas hechas a través de
                            depósito parcial, en caso de cancelación, el
                            depósito no es reembolsable.
                        </li>
                        <li>
                            Modificaciones de nombres no tienen costo (excepto
                            en la compra de entradas nominales a atracciones que
                            lo requieren).
                        </li>
                        <li>
                            Modificaciones de participantes a la alza tienen
                            costo adicional por persona y sujetos a
                            disponibilidad el día de la Reserva Confirmada.
                        </li>
                    </ul>
                </Typography>
                <ListItemHeader>2. No presentación (No show).</ListItemHeader>
                <Typography mb={2}>
                    Si Usted, su(s) acompañante(s) o alguno de ellos/sus
                    clientes simplemente no se presenta(n) al tour o servicio
                    contratado (no show) = No aplica ninguna clase de reembolso
                    por ningún motivo y bajo ninguna circunstancia.
                </Typography>
                <ListItemHeader>3. Caso fortuito/fuerza</ListItemHeader>
                <Typography mb={2}>
                    Esta Política no aplica en casos fortuitos o fuerza mayor
                    como pueden ser huracán, puerto cerrado, trafico excesivo,
                    inundaciones, caminos enlodados o bloqueados, tormentas
                    tropicales, epidemias, pandemias o cualquier otro fenómeno
                    natural o causado por el hombre y ajenos a TREKZY; para
                    estos casos solamente se aplicará la política de cambio de
                    fecha de su Reserva Confirmada y caso por caso y a entera
                    discreción de TREKZY podrá aplicar el reembolso total o
                    parcial de la Reserva Confirmada.
                </Typography>
                <ListItemHeader>4. Forma de pago del reembolso</ListItemHeader>
                <Typography mb={2}>
                    Si la Reserva Confirmada ha sido efectivamente cobrada por
                    TREKZY le pediremos que nos indique la cuenta bancaría
                    ligada a la tarjeta asociada a su Reserva Confirmada para
                    realizarle el reembolso de la misma.
                    <br />
                    <br />
                    Si la Reserva Confirmada no ha sido efectivamente cobrada
                    por TREKZY realizaremos las gestiones necesarias para
                    cancelar el cargo de la Reserva Confirmada. Por favor tome
                    en cuenta que el tiempo en que Usted verá reflejado el
                    reembolso de los fondos o la cancelación del cargo no
                    depende de TREKZY, le sugerimos contactar al banco emisor de
                    su tarjeta para mayor información al respecto.
                </Typography>
                <ListItemHeader>
                    5. Términos y Condiciones del Sitio / privacidad de datos
                </ListItemHeader>
                <Typography mb={2}>
                    Para información relacionada con los términos y condiciones
                    del Sitio y como manejamos sus datos por favor refiérase a
                    los términos y condiciones y a la política de privacidad
                    contenidos en el Sitio.
                </Typography>
                <ListItemHeader>
                    6. Modificaciones y actualizaciones de la presente política
                </ListItemHeader>
                <Typography mb={2}>
                    TREKZY podrá modificar, ampliar, corregir o actualizar la
                    presente Política, en cualquier momento y su entera
                    discreción. En todos los casos, publicaremos en el Sitio la
                    fecha de última actualización de esta Política.
                    <br />
                    <br />
                    También podremos comunicar estos cambios a través del Sitio
                    o mediante el envío de un correo electrónico a Usted en caso
                    de contar con su dirección pero sin estar obligado a ello.
                </Typography>
                <ListItemHeader>7. Contacto</ListItemHeader>
                <Typography mb={2}>
                    Para más información o asistencia, por favor contáctenos en
                    el apartado de Contacto del Sitio.
                </Typography>
            </Box>
        </StaticPagesWrapper>
    </MainLayout>
)

export default CancelationPolicies
