import React, { useEffect } from "react"
import { useTranslation } from 'react-i18next';
import { Box, Typography } from "@mui/material"
import TagButton from "../TagButton"
import { addTour } from '../../app/features/cartSlice'
import { useDispatch, useSelector } from "react-redux"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import { prePrareTourBookingData } from "../../utils/checkout"
import { selectTotalPrice } from '../../app/features/cartSlice';

const PaymentSummary = ({
    items,
    iva = 0,
    tour,
    date
}) => {

    const { t } = useTranslation(['tours', 'paths', 'components']);
    const [totalPrice, setTotalPrice] = React.useState(0);
    const navigate = useNavigate();

    const totalBooking = useSelector(selectTotalPrice) || Math.ceil((totalPrice * 1.21));

    useEffect(() => {
        const subTotal = items.reduce((total, item) => total + (item.price * item.value), 0);
        const total = tour?.country.slug == 'espana' ? Math.ceil((subTotal * 1.21)) : subTotal;
        setTotalPrice(total)
    }, [items])

    const dispatch = useDispatch();


    const addToCart = () => {
        console.log('--->', tour, items);
        const tourtoBookv2 = prePrareTourBookingData(tour, date, items);
        dispatch(addTour(tourtoBookv2));
        navigate('/checkout')
    }

    return (
        <Box sx={{
            backgroundColor: '#F0F0F1',
            borderRadius: "5px",
            p: "1.2rem",
        }}>
            <Typography color="secondary" variant="h5" mb={1}>
                {t('components:ordersummary')}
            </Typography>
            {
                items.map((item, index) => (
                    <Box
                        key={`${item.title}${index}`}
                        sx={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: ".5rem",
                        }}
                    >
                        <Typography
                            color="secondary"
                            sx={{
                                fontSize: 14,
                                fontFamily: "inter",
                            }}
                        >
                            {item.title}
                        </Typography>
                        <Typography
                            color="secondary"
                            sx={{
                                fontSize: 14,
                                fontFamily: "inter",
                            }}
                        >
                            {item.price * item.value} €
                        </Typography>
                    </Box>
                ))
            }
            <Box sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: "1.5rem",
            }}
            >
                <Typography color="secondary" sx={{ lineHeight: 1 }}>
                    Total:
                </Typography>
                <Typography color="secondary" variant="h5" sx={{ lineHeight: 1 }}>
                    {totalPrice}€
                </Typography>
            </Box>
            <Typography
                color="grayText"
                sx={{
                    fontSize: 12,
                    fontFamily: "inter",
                    textAlign: "right",
                    mb: "1.5rem",
                }}
            >
                {tour?.country.slug == 'espana' ? t('IVAincluded') : ''}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <TagButton
                    text={t('components:booking-button')}
                    onClick={addToCart}
                    id='personalizar-btn'
                    sx={{
                        borderRadius: "2rem",
                        px: "1.5rem",
                        fontSize: 16,
                        fontFamily: 'Poppins',
                        fontWeight: 600,
                        lineHeight: 1.5,
                        mb: "1rem",
                        py: { xs: "1rem", md: "3px" },
                    }}
                />
            </Box>
        </Box>
    )
}

export default PaymentSummary
