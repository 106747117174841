import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from "@mui/material";
import { atracciones, categorias, duraciones, timeSlider, priceSlider } from "../../utils/destinationPageMockData";
import GroupCheckBoxesFilter from "./GroupCheckBoxesFilter";
import { SearchInput, SearchInputFilterBox } from "../SearchInput"
import dayjs from "dayjs";
import RangeSlider from "./RangeSlider"
import Calendar from "./Calendar"
import useFetchCategories from "../../hooks/API/Tours/useFetchCategories";
import useFetchAttractions from "../../hooks/API/Tours/useFetchAttractions";


const Filters = ({ city = null, maxPrice = 0, priceRange, onApplyFilters }) => {
  const { t } = useTranslation(['components']);
  const { i18n } = useTranslation();
  const [boxAttractions, setBoxAttractions] = useState(atracciones);
  const [boxCategories, setBoxCategories] = useState(categorias);
  const [boxDurations, setBoxDurations] = useState(duraciones);

  const { categories, isLoadingCat } = useFetchCategories();
  const { attractions, isLoadingAtt } = useFetchAttractions(city);

  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState("");
  const [date, setDate] = useState(dayjs('2024-04-17'));
  const [valueTimeRange, setValueTimeRange] = useState([0, 24]); // Min y Max del horario
  const [valuePriceRange, setValuePriceRange] = useState([0, maxPrice]); // Min y Max del precio
  const [sortBy, setSortBy] = useState("price");
  const [orderBy, setOrderBy] = useState("desc");

  React.useEffect(() => {
    if (!isLoadingCat && categories) {
      setBoxCategories(categories);
    }

    if (!isLoadingAtt && attractions && city != null) {
      setBoxAttractions(attractions);
    }

    if (maxPrice > 0) {
      setValuePriceRange([0, maxPrice]);
    }
  }, [categories, isLoadingCat, attractions, isLoadingAtt, maxPrice]);

  const handleChangeSlider1 = (event, newValue) => {
    setValueTimeRange(newValue);
  }

  const handleChangeSlider2 = (event, newValue) => {
    setValuePriceRange(newValue);
  }

  const handleChangeCalendar = (event, newDate) => {
    setDate(newDate)
  }

  const filteredAttractions = boxAttractions.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredCategories = boxCategories.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredDurations = boxDurations.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  function makeHandler(checkBoxes, setCheckBoxes) {
    return function (id) {
      setCheckBoxes(checkBoxes.map(item => item.name == id ? { ...item, isChecked: !item.isChecked } : item))
    }
  }

  function clearAllBoxes(setCheckBoxes) {
    setCheckBoxes(prevCheckBoxes =>
      prevCheckBoxes.map(item => ({ ...item, isChecked: false }))
    );
  }

  function clearFilters() {
    setSearchTerm('');
    setSearchValue('');
    setValueTimeRange([0, 24]);
    setValuePriceRange([0, maxPrice]);

    clearAllBoxes(setBoxAttractions);
    clearAllBoxes(setBoxCategories);
    clearAllBoxes(setBoxDurations);
  }

  function handleApplyFilters() {
    const selectedFilters = {
      city: city || "",
      categories: boxCategories.filter(item => item.isChecked).map(item => item.id),
      attractions: boxAttractions.filter(item => item.isChecked).map(item => item.id),
      duration: boxDurations.filter(item => item.isChecked).map(item => item.id),
      schedulerange: valueTimeRange,
      priceRange: valuePriceRange,
      date: date.format("YYYY-MM-DD")
    };

    const selectedFiltersAPI = {
      name: searchValue || null,
      city: city?.city || null,
      // duration: boxDurations.filter(item => item.isChecked).map(item => ({ name: item.id })),
      duration: boxDurations.filter(item => item.isChecked).map(item => ({ min: item.min, max: item.max })),
      pricerange: {
        min: valuePriceRange[0],
        max: valuePriceRange[1]
      },
      schedulerange: {
        min: valueTimeRange[0],
        max: valueTimeRange[1]
      },
      categories: boxCategories.filter(item => item.isChecked).map(item => ({ name: item.name })),
      attractions: boxAttractions.filter(item => item.isChecked).map(item => ({ name: item.name })),
      page: 1,
      sort: sortBy,
      order: orderBy
    };

    onApplyFilters(selectedFiltersAPI);

    // onApplyFilters(selectedFilters); // Llamar la función del padre con los filtros
  }

  return (
    <>
      <Box sx={{
        px: { xs: '15px', md: 0 },
        mb: '100px'
      }}>
        <Box sx={{
          pb: '10px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <Typography
            sx={{
              color: '#000000'
            }}>{t('filters')}</Typography>
          <Button
            sx={{
              pt: 0,
              display: { xs: 'block', md: 'none' },
              color: '#666B6E'
            }}>{t('delete')}</Button>
        </Box>
        <SearchInputFilterBox
          onSearch={setSearchTerm}
          value={searchValue}
          setValue={setSearchValue}
          placeHolder={t('searchInput-placeholder-keyword')}
        />
        <GroupCheckBoxesFilter
          title={t('attractions')}
          checkBoxes={filteredAttractions}
          handleOnChange={makeHandler(boxAttractions, setBoxAttractions)}
        />
        <GroupCheckBoxesFilter
          title={t('categories')}
          checkBoxes={filteredCategories}
          handleOnChange={makeHandler(boxCategories, setBoxCategories)}
        />
        <RangeSlider title={t('startingtime')} {...timeSlider} valueSlider={valueTimeRange} handleChange={handleChangeSlider1} />
        <GroupCheckBoxesFilter
          title={t('duration')}
          checkBoxes={filteredDurations}
          handleOnChange={makeHandler(boxDurations, setBoxDurations)}
        />
        <RangeSlider title={t('price')} {...priceRange} valueSlider={valuePriceRange} handleChange={handleChangeSlider2} />

        <Box sx={{
          width: '100%',
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'space-between'
        }}>
          <Button
            onClick={clearFilters}
            sx={{
              fontSize: '14px',
              pt: 0,
              color: '#666B6E',
              fontWeight: 600,
              padding: '0px 8px'
            }}>{t('delete-filters')}</Button>
          <Button
            onClick={handleApplyFilters}
            sx={{
              fontSize: '13px',
              width: '120px',
            }}
            variant="contained"
          >{t('apply')}</Button>
        </Box>
      </Box>
      <Box sx={{
        width: '100%',
        backgroundColor: '#ffffff',
        display: { xs: 'flex', md: 'none' },
        justifyContent: 'space-between',
        py: '20px',
        px: '10px',
        boxShadow: 10,
        position: 'fixed',
        bottom: 0
      }}>
        <Button
          onClick={handleApplyFilters}
          sx={{
            fontSize: '13px',
            width: '100%',
          }}
          variant="contained"
        >{t('apply')}</Button>
      </Box>
    </>

  )
}

export default Filters
