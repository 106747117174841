import React from "react"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import {
    Box,
    Button,
    Collapse,
    Divider,
    Typography
} from "@mui/material"
import { InfoOutlined } from "@mui/icons-material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";

const DetailsSection = ({
    children,
    isExpanded,
    title,
    onCollapse,
    infoIcon = false,
    infoDetails = 'Operador'
}) => (
    <Box sx={{ mb: "1rem" }}>
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%"
        }}>
            <Typography component="h2"
                sx={{
                    color: "#223F7F",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: 700,
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    mb: "1rem",
                }}>
                {title} {infoIcon == true ?
                    <Tooltip title={infoDetails} arrow>
                        <InfoOutlinedIcon />
                    </Tooltip> :
                    ''}
            </Typography>
            <Button
                variant="text"
                sx={{
                    color: "#223F7F",
                    display: { xs: "inherit", md: "none" },
                }}
                onClick={() => onCollapse(title)}
            >
                {!isExpanded ? <ExpandMore /> : <ExpandLess />}
            </Button>
        </Box>
        <Divider aria-hidden="true" color='secondary' sx={{ height: 2 }} />
        <Collapse
            in={isExpanded}
            timeout='auto'
            unmountOnExit
        >
            <Box
                sx={{
                    color: "#666B6E",
                    display: "flex",
                    flexDirection: "column",
                    fontFamily: "Inter",
                    fontSize: "1rem",
                }}
            >
                {children}
            </Box>
        </Collapse>
    </Box>
)

export default DetailsSection
