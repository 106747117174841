import React from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@mui/material'
import './imagesGrid.css'
import ImageCard from '../ImageCardWithRating'
import { useScreenDetector } from '../../hooks/useScreenDetector'

const ImagesGrid = ({ title, text, itemData }) => {
    const { t } = useTranslation(['home', 'paths']);
    const [isHovered, setIsHovered] = React.useState(false)
    const [imageTitle, setImageTitle] = React.useState('')
    const { isMobile } = useScreenDetector()

    const handleMouseOver = (event) => {
        setImageTitle(event.target.alt)
        setIsHovered(true)
    }

    const handleMouseOut = () => {
        setIsHovered(false)
        setImageTitle('')
    }

    return (
        <Box sx={{ my: '40px' }}>
            <Grid container sx={{ mb: 4 }}>
                <Grid item xs={12} md={9}>
                    <Typography
                        color="primary"
                        variant="subtitle1"
                        component="h2"
                        sx={{
                            fontSize: { xs: '20px', md: '32px' },
                            fontWeight: 700,
                            textAlign: { xs: 'center', md: 'left' },
                            color: 'secondary.main'
                        }}>
                        {title}
                    </Typography>
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            textAlign: { xs: 'center', md: 'left' },
                        }}
                    >
                        {text}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={3}
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Button
                        variant="contained"
                        color="primary"
                        href={`/${t('destinations')}`}
                        title={t('favoriteDestinations-buttontitle')}
                        sx={{
                            borderRadius: '16px',
                            height: 'fit-content',
                            color: '#ffffff',
                            display: 'grid',
                            textTransform: 'none'
                        }}>
                        {t('favoriteDestinations-button')}
                    </Button>
                </Grid>
            </Grid>
            <div className="masonry-grid">
                {itemData.map((item, index) => {
                    const hover = isHovered && imageTitle === item?.city?.name
                    const masonry =
                        index === 2 || index === 4
                            ? `image-block ${'byindex'}`
                            : 'image-block'

                    return (
                        isMobile ? <div
                            key={`${index}-${item?.city?.name}`}
                            className={masonry}
                            style={{
                                gridArea: `item${index + 1}`
                            }}>
                            <ImageCard
                                image={item?.image}
                                title={item?.city?.name}
                                country={item?.country?.slug}
                                city={item?.city?.slug}
                                hover={false}
                            />
                        </div> : <div
                            onMouseOver={handleMouseOver}
                            onMouseLeave={handleMouseOut}
                            key={`${index}-${item?.city?.name}`}
                            className={masonry}
                            style={{
                                gridArea: `item${index + 1}`
                            }}>
                            <ImageCard
                                image={item?.image}
                                title={item?.city?.name}
                                country={item?.country?.slug}
                                city={item?.city?.slug}
                                hover={hover}
                            />
                        </div>
                    )
                })}
            </div>
            <Box
                justifyContent="center"
                alignItems="center"
                sx={{
                    display: { xs: 'flex', md: 'none' },
                    mt: { xs: 2 }
                }}>
                <Button
                    variant="contained"
                    color="primary"
                    href={`/${t('destinations')}`}
                    sx={{
                        borderRadius: '16px',
                        height: 'fit-content',
                        color: '#ffffff',
                        display: 'grid',
                        textTransform: 'none'
                    }}>
                    {t('all-destinations')}
                </Button>
            </Box>
        </Box>
    )
}

export default ImagesGrid
