
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { DateCalendar } from "@mui/x-date-pickers";
import dayjs from "dayjs";
const normalizeDays = (day) => (day === 0 ? 7 : day);
const rotateDay = (day) => (day === 0 ? 1 : day === 6 ? 0 : day + 1);

const Calendar = ({ title, cutOff, date, handleChange, availableDays = [] }) => {

    const shouldDisableDate = (date) => {
        if (!date) return false; // Manejo seguro por si date es null
        const available = availableDays.split(',').map(Number); // [1, 2, 3]
        const dayOfWeek = rotateDay(date.day()); // 0 = domingo, 1 = lunes, ...
        const isDayDisabled = !available.includes(dayOfWeek);
        const cutoffTime = dayjs().add(cutOff, 'hours');
        const isAfterCutoff = date.isBefore(cutoffTime, 'day');


        return isDayDisabled || isAfterCutoff;
    };


    return (<Accordion defaultExpanded sx={{ mb: '25px', borderRadius: '8px' }}>
        <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls={`${title}-content`}
            id={`${title}-header`}
        >
            <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ height: '340px' }}>
            <DateCalendar shouldDisableDate={shouldDisableDate} minDate={dayjs(new Date())} sx={{ width: 'auto', height: 'auto' }} value={date} onChange={handleChange} />
        </AccordionDetails>
    </Accordion>)
}
export default Calendar;
