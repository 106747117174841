import React from 'react'
import { useTranslation } from 'react-i18next';
import { Container } from '@mui/material'
import { Helmet } from "react-helmet";
import CardsTab from '../../components/CardsTab'
import CarouselIconCards from '../../components/CarouselIconCards'
import GuidesBanner from '../../components/GuidesBanner'
import Hero from '../../components/Hero'
import ImagesGrid from '../../components/ImagesGrid'
import ReviewsCarousel from '../../components/ReviewsCarousel'
import { opacities } from '../../components/BlurImageWrapper'
import { selectTours } from '../../app/features/cartSlice'
import {
    getCardsByTours,
    dataCarousel
} from '../../utils/data'
import './styles.css'
import { useSelector } from 'react-redux'
import MainLayout from '../../components/MainLayout'
import { ReactComponent as TrekzyPattern } from '../../assets/images/trekzy-pattern.svg'
import useFetchDestinationsFeatured from '../../hooks/API/Destinations/useFetchDestinationsFeatured'
import homeBgImage from '../../assets/images/hero.png'


const Home = () => {
    const { t } = useTranslation('home');
    const { i18n } = useTranslation();
    const { destinationsFeatured: destinationsData, isLoading: isDestinationsLoading } = useFetchDestinationsFeatured();
    const tours = useSelector(selectTours)
    const cardTabs = [
        {
            name: 'Playa',
            data: [...getCardsByTours('Playa')]
        },
        {
            name: 'Montaña',
            data: [...getCardsByTours('Montaña')]
        },
        {
            name: 'Ciudad',
            data: [...getCardsByTours('Ciudad')]
        },
        {
            name: 'Rural',
            data: [...getCardsByTours('Rural')]
        }
    ]

    console.log("Idioma actual:", i18n.language);
    return (
        <MainLayout>
            <Helmet>
                <title>{t('page')}</title>
                <meta
                    name="description"
                    content={t('page')}
                    data-react-helmet="true"
                />
            </Helmet>
            <Helmet>
                <meta
                    name="robots"
                    content="index, follow"
                    data-react-helmet="true"
                />
            </Helmet>
            <Hero
                title={t('hero-title')}
                type='span'
                text={t('hero-text')}
                footerTitle={t('hero-footerTitle')}
                footerText={t('hero-footerText')}
                placeHolderText={t('hero-placeHolderText')}
                bgImage={homeBgImage}
                opacity={opacities.dark}
                renderVector
            />
            <Container>
                <section className="section">
                    <CarouselIconCards />
                </section>
            </Container>
            <section className="section pattern-container">
                <Container>
                    {!isDestinationsLoading && <ImagesGrid
                        title={t('favoriteDestinations-title')}
                        text={t('favoriteDestinations-subtext')}
                        itemData={destinationsData}
                    />}
                    <div className='pattern-img' />
                </Container>
            </section>
            <section className="section">
                <CardsTab tabData={cardTabs} />
            </section>
            <section>
                <GuidesBanner />
                <ReviewsCarousel data={dataCarousel} />
            </section>
        </MainLayout>
    )
}

export default Home
