import React from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom'
import { Box, Container, Grid, Typography } from '@mui/material'
import { Helmet } from "react-helmet";
import BlurImageWrapper, { orientations } from '../../components/BlurImageWrapper'
import MainLayout from '../../components/MainLayout'
import teamMembers from '../../assets/images/teamwork.jpg'
import SingleCard from '../../components/AboutUs/SingleCard'
import SingleProductCard from '../../components/AboutUs/SingleProductCard'
import ImagesBanner from '../../components/AboutUs/ImagesBanner'
import ContactUs from '../../components/AboutUs/ContactUs'
import TeamSection from '../../components/AboutUs/TeamSection'
import TagButton from '../../components/TagButton'
import SubscribeBanner from '../../components/AboutUs/SubscribeBanner'

const AboutUs = () => {
    const { t } = useTranslation(['aboutus']);
    const { hash } = useLocation();

    React.useEffect(() => {
        if (hash) {
            const id = hash.replace('#', '')
            document.getElementById(id)?.scrollIntoView({
                behavior: 'smooth'
            })
        }
    }, [hash])

    return (
        <MainLayout>
            <Helmet>
                <title>{t('page')}</title>
                <meta
                    name="description"
                    content={t('metadescription')}
                    data-react-helmet="true"
                />
            </Helmet>
            <Helmet>
                <meta
                    name="robots"
                    content="index, follow"
                    data-react-helmet="true"
                />
            </Helmet>
            <BlurImageWrapper
                imageUrl={teamMembers}
                altText='Sobre Nosotros- Quienes somos'
                orientation={orientations.bottomToTop}
                styles={{ backgroundPosition: 'top' }}>
                <Container
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        py: '8rem',
                        maxWidth: '1280px'
                    }}>
                    <Typography
                        color='secondary.contrastText'
                        variant='h3'
                        component='h1'
                        sx={{ mb: '1rem', fontWeight: 'bold' }}>
                        {t('hero-title')}
                    </Typography>
                    <Typography
                        variant='body1'
                        color='secondary.contrastText'
                        sx={{
                            textAlign: 'center',
                            maxWidth: 1024
                        }}></Typography>
                </Container>
            </BlurImageWrapper>
            <Container
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 2,
                    mt: { xs: '2rem', md: '4rem' },
                    px: { md: '2rem' },
                    width: '100%'
                }}>
                <SingleCard
                    title={t('subtitle1')}
                    description={t('text1')}
                    style={{ marginRight: '2rem' }}
                />
                <SingleCard
                    title={t('subtitle2')}
                    description={t('text2')}
                />
            </Container>
            <Container
                sx={{
                    width: '100%',
                    my: '4rem'
                }}>
                <Grid container columnSpacing={1} rowSpacing={1}>
                    <Grid
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: '1rem'
                        }}>
                        <SingleProductCard
                            image='happy-couple.jpg'
                            title={t('card1-title')}
                            description={t('card1-text')}
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: '1rem'
                        }}>
                        <SingleProductCard
                            image='young-traveler.jpg'
                            title={t('card2-title')}
                            description={t('card2-text')}
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: '1rem'
                        }}>
                        <SingleProductCard
                            image='teacher.jpg'
                            title={t('card3-title')}
                            description={t('card3-text')}
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: '1rem'
                        }}>
                        <SingleProductCard
                            image='tutor.jpg'
                            title={t('card4-title')}
                            description={t('card4-text')}
                        />
                    </Grid>
                </Grid>
            </Container>
            <TeamSection />
            <Container
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mb: { xs: '1.5rem', md: '2rem' },
                    textAlign: 'center'
                }}>
                <Typography
                    variant='title'
                    component="h2"
                    color='secondary.main'
                    sx={{ mt: { xs: '3rem', md: '4rem' }, mb: '1rem' }}>
                    {t('madetravelers-title')}
                </Typography>
                <Typography textAlign='center' mb={2}>
                    {t('madetravelers-text1')}
                </Typography>
            </Container>
            <ImagesBanner />
            <Container sx={{ mt: { xs: '2rem', md: '4rem' } }}>
                <Typography textAlign='center'>
                    {t('madetravelers-text2')}
                </Typography>
                <Typography
                    component='h2'
                    variant='title'
                    color='secondary'
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        mt: '3rem',
                        mb: '1rem',
                        textAlign: 'center'
                    }}>
                    {t('moreaboutus-title')}
                </Typography>
                <Typography
                    component='p'
                    variant='textbody2'
                    sx={{
                        textAlign: 'center',
                        mb: '3rem',
                        display: { xs: 'none', md: 'block' }
                    }}>
                    {t('moreaboutus-text')}
                </Typography>
                <Box width='100%' mb={8} sx={{
                    display: { xs: 'none', md: 'block' }
                }}>
                    <iframe
                        width='100%'
                        height='415'
                        src='https://www.youtube.com/embed/uGh5AdlGmMk?si=kXgem83SNIEJnwjT'
                        title={t('Welcome to Trekzy')}
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        referrerPolicy='strict-origin-when-cross-origin'
                        style={{
                            borderRadius: 8,
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: { xs: 'flex', md: 'none' },
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'center',
                        mb: '2.5rem'
                    }}>
                    <Typography color='secondary' fontSize={18} mb={1} sx={{ mt: { xs: 4, md: 0 } }}>
                        {t('contact-title')}
                    </Typography>
                    <Typography
                        color='secondary'
                        fontSize={24}
                        fontWeight={700}
                        mb={2}>
                        {t('contact-subtitle')}
                    </Typography>
                    <TagButton
                        text={t('contact-button')}
                        href='tel:+34666666666'
                        sx={{
                            borderRadius: 7,
                            fontSize: 19,
                            py: '.5rem',
                            width: '15rem'
                        }}
                    />
                </Box>
            </Container>
            <section id='contacto'>
                <ContactUs />
            </section>
            <SubscribeBanner />
        </MainLayout>
    )
}

export default AboutUs
