import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Container } from '@mui/material'
import BlurImageWrapper from '../BlurImageWrapper'
import bgImage from '../../assets/images/people-map.png'
import './styles.css'

const GuidesBanner = () => {
    const { t } = useTranslation(['home', 'paths']);
    return (
        <BlurImageWrapper imageUrl={bgImage}>
            <Container
                sx={{
                    height: { xs: '23rem', md: 665 },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: { xs: 'center', md: 'left' },
                    alignItems: { xs: 'center', md: 'normal' },
                    py: { xs: '3rem', md: '5.5rem' }
                }}>
                <h2 className="title-guidehome">
                    {t('destinationGuides-title1')}{' '}
                    <strong className="title-end">{t('destinationGuides-title2')}</strong>
                </h2>
                <p className="description">
                    {t('destinationGuides-text')}
                </p>
                <Button
                    title={t('destinationGuides-text')}
                    variant="contained"
                    color="primary"
                    href={`/${t('guides')}`}
                    sx={{
                        borderRadius: '16px',
                        color: '#ffffff',
                        height: 'fit-content',
                        fontWeight: 600,
                        mt: 'auto',
                        textTransform: 'none',
                        width: '12rem'
                    }}>
                    {t('destinationGuides-button')}
                </Button>
            </Container>
        </BlurImageWrapper>
    )
}

export default GuidesBanner
