import React from "react";
import { useTranslation } from 'react-i18next';

import { Grid, TextField } from "@mui/material";

const CmLabel = (props) => {
    const { children, ...lProps } = props;
    return (
        <label style={{ color: '#6D7280', fontSize: '14px' }} {...lProps}>{children}</label>
    );
}

const FormTitular = ({ formData, handleChange, errors }) => {
    const { t } = useTranslation(['checkout', 'components']);
    return (
        <form>
            <Grid container spacing={3}
                sx={{
                    '& .MuiInputBase-input': {
                        p: '7px 10px',
                        bgcolor: '#F9FAFB',
                        border: '1px solid #F9FAFB'
                    }
                }}
            >
                <Grid item xs={12} md={6}
                    sx={{ display: 'grid' }}
                >
                    <CmLabel htmlFor="name">{t('form-firstName')}*</CmLabel>
                    <TextField
                        name="name"
                        type="text"
                        error={errors?.name ? true : false}
                        placeholder={t('form-placeholder')}
                        value={formData.name}
                        onChange={handleChange}
                        sx={{
                            p: 0
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6}
                    sx={{ display: 'grid' }}
                >
                    <CmLabel htmlFor="lastName">{t('form-lastName')}*</CmLabel>
                    <TextField
                        name="lastName"
                        type="text"
                        error={errors?.lastName ? true : false}
                        placeholder={t('form-placeholder')}
                        value={formData.lastName}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}
                    sx={{ display: 'grid' }}
                >
                    <CmLabel htmlFor="email">{t('form-email')}*</CmLabel>
                    <TextField
                        name="email"
                        type="email"
                        error={errors?.email ? true : false}
                        placeholder={t('form-placeholder')}
                        value={formData.email}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6}
                    sx={{ display: 'grid' }}
                >
                    <CmLabel htmlFor="confirmEmail">{t('form-confirmEmail')}*</CmLabel>
                    <TextField
                        name="confirmEmail"
                        type="email"
                        error={errors?.email ? true : false}
                        placeholder={t('form-placeholder')}
                        value={formData.confirmEmail}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={12}
                    sx={{ display: 'grid' }}
                >
                    <CmLabel htmlFor="passport">{t('form-document')}*</CmLabel>
                    <TextField
                        name="passport"
                        type="text"
                        placeholder={t('form-placeholder')}
                        error={errors?.passport ? true : false}
                        value={formData.passport}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={12}
                    sx={{ display: 'grid' }}
                >
                    <CmLabel htmlFor="phone">{t('form-phone')}*</CmLabel>
                    <TextField
                        name="phone"
                        type="text"
                        error={errors?.phone ? true : false}
                        placeholder={t('form-placeholder')}
                        value={formData.phone}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </form>
    )
}

export default FormTitular;