import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Typography } from '@mui/material'
import BlurImageWrapper from '../BlurImageWrapper'

const DataBox = (labelText, value) => (
    <Box sx={{ pl: '25px' }}>
        <Typography
            variant="body1"
            component="p"
            sx={{
                color: 'primary.contrastText',
                fontSize: { xs: '18px', md: '22px' },
                fontWeight: 500,
            }}
        >
            {value}
        </Typography>
        <Typography
            variant="body1"
            component="p"
            sx={{
                color: 'primary.contrastText',
                fontSize: { xs: '12px', md: '16px' }
            }}
        >
            {labelText}
        </Typography>
    </Box>
)

const DestinationHero = ({
    country,
    city,
    numberComments,
    media,
    numberActivities,
    bgImage
}) => {
    const { t } = useTranslation(['tours']);

    return (
        <BlurImageWrapper imageUrl={bgImage}>
            <Container
                sx={{
                    height: 'fit-content',
                    pt: { xs: '3rem', md: '5.5rem' },
                }}>
                <Box sx={{
                    width: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                    <Typography
                        variant="h1"
                        align='center'
                    >
                        <Typography
                            component="span"
                            color='primary'
                            sx={{
                                fontSize: 22,
                                fontFamily: 'Poppins',
                                fontWeight: 'bold',
                                lineHeight: 1.2,
                            }}
                        >
                            {t('hero-title')}&nbsp;
                        </Typography>
                        <Typography
                            component="span"
                            className='title'
                            color='secondary.contrastText'
                            sx={{
                                fontWeight: 'bold',
                                mb: '1rem',
                            }}
                        >
                            {city}
                        </Typography>
                    </Typography>
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            color: 'primary.contrastText',
                            fontSize: '22px'
                        }}
                    >
                        {country}
                    </Typography>
                </Box>
                <Box sx={{ pb: '20px', display: 'flex' }}>
                    {DataBox(t('reviews'), numberComments)}
                    {DataBox(t('averagescore'), media)}
                    {DataBox(t('totalactivities'), numberActivities)}
                </Box>
            </Container>
        </BlurImageWrapper>
    );
}

export default DestinationHero;