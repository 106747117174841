import { Avatar, IconButton } from "@mui/material"
import { ReactComponent as InstagramLogo } from "../../assets/icons/instagram.svg";
import { ReactComponent as FacebookLogo } from "../../assets/icons/facebook.svg";
import { ReactComponent as LinkedinLogo } from "../../assets/icons/linkedin.svg";
import { ReactComponent as TiktokLogo } from "../../assets/icons/tiktok.svg";
import { ReactComponent as YoutubeLogo } from "../../assets/icons/youtube.svg";
import { useTranslation } from 'react-i18next';

export const useSocialVariants = () => {
    const { i18n } = useTranslation();

    return {
        instagram: {
            icon: InstagramLogo,
            url: i18n.language === "es"
                ? "https://www.instagram.com/trekzy_official/"
                : "https://www.instagram.com/trekzy_official/"
        },
        facebook: {
            icon: FacebookLogo,
            url: "https://www.facebook.com/trekzy.official"
        },
        linkedin: {
            icon: LinkedinLogo,
            url: "https://www.linkedin.com/company/trekzy"
        },
        tiktok: {
            icon: TiktokLogo,
            url: "https://www.tiktok.com/@trekzy_private_tours?_t=ZM-8v8WlwoRgEo&_r=1"
        },
        youtube: {
            icon: YoutubeLogo,
            url: "https://www.youtube.com/@Trekzy-PrivateTours"
        }
    };
};

const SocialLink = ({ variant = "" }) => {
    const socialVariants = useSocialVariants();
    const linkData = socialVariants[variant];

    if (!linkData) return null;

    const { icon: Icon, url } = linkData;

    return (
        <IconButton href={url} target="_blank">
            <Avatar sx={{ backgroundColor: "#223F7F" }}>
                <Icon style={{ width: "1rem", height: "1rem" }} />
            </Avatar>
        </IconButton>
    );
};

export default SocialLink;