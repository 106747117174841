import React, { useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Typography
} from "@mui/material";
import ProductCard2 from "../ProductCard2";
import { useSelector } from "react-redux";
import { ReactComponent as MinusCircle } from '../../assets/icons/minus-circle.svg'
import { selectTotalPrice } from '../../app/features/cartSlice';

const CartModal = ({ cartTours, total = 300, iva = 25, setOpenCartModal }) => {
    const { t } = useTranslation(['checkout', 'components']);

    const toursInCart = useSelector(state => state.cart.checkout.tours)
    const totalPrice = useSelector(selectTotalPrice);




    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setOpenCartModal(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setOpenCartModal]);

    return (
        <Card
            ref={modalRef}
            variant="outlined"
            sx={{
                width: '450px',
                position: 'absolute',
                zIndex: '100',
                backgroundColor: '#FFFFFF',
                right: { md: '6%', lg: '10%' },
                mt: '2px'
            }}
        >
            <CardContent sx={{
                pb: '8px'
            }}>
                {toursInCart.map(item => (
                    <ProductCard2 key={item.name} data={item} isCartModal={true} />
                ))}
                {toursInCart.length === 0 && (
                    <Typography
                        sx={{
                            textAlign: 'center',
                            justifyContent: 'center',
                            fontSize: '14px',
                            color: '#223F7F'
                        }}
                    >
                        <MinusCircle style={{
                            width: 72,
                            height: 72
                        }} /> <br />
                        {t('cart-empty-message')}
                    </Typography>
                )}
            </CardContent>
            {toursInCart.length !== 0 && (
                <CardActions
                    sx={{
                        px: '22px',
                        py: '20px',
                        display: 'block',
                        backgroundColor: '#F0F0F1'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: '#223F7F'
                            }}
                        >
                            Total:
                        </Typography>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: { xs: '14px', md: '18px' },
                                    fontWeight: { xs: 600, md: 700 },
                                    color: '#223F7F'
                                }}>
                                {`${totalPrice} €`}
                            </Typography>
                            <Typography
                                color="grayText"
                                sx={{
                                    textAlign: 'right',
                                    fontSize: { xs: '10px', md: '12px' },
                                    fontWeight: 400
                                }}>
                                {/* {`${iva}€ IVA`} */}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <Button
                            href="/checkout"
                            variant="contained"
                        >{t('cartmodal-checkout-button')}</Button>
                    </Box>
                </CardActions>
            )}
        </Card>
    )
}

export default CartModal;