import React, { useContext } from "react";

import { ReactComponent as Edit } from '../../../assets/icons/edit-2.svg'
import { ReactComponent as Trash } from '../../../assets/icons/trash.svg'
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import CheckoutContext from "../../../components/CheckoutContext/checkoutContext";
import { getAssistantsString } from "../../../utils/checkout";
import { remove, selectProductTotal } from "../../../app/features/cartSlice";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SimpleProductCard = ({ data }) => {
    const {
        name,
        cover,
        price,
        booking,
        date,
        slug
    } = data;
    const context = useContext(CheckoutContext);
    const dispatch = useDispatch();
    const tours = useSelector(state => state.cart.checkout.tours.length);
    const navigate = useNavigate();
    const total = useSelector((state) => selectProductTotal(state, name));

    return (
        <Box key={name} sx={{ pb: '50px' }}>
            <Grid container>
                <Grid
                    item
                    xs={3}
                    sx={{ px: '0!important' }}
                >
                    <Box
                        sx={{
                            borderRadius: '8px',
                            overflow: 'hidden',
                            height: '55px',
                            width: '55px'
                        }}
                    >
                        <img
                            src={cover}
                            alt={name}
                            height={55}
                            width={55}
                        />
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={7}
                    sx={{ pl: '5px!important' }}
                >
                    <Typography
                        sx={{ fontSize: '13px!important' }}
                        variant="subtitle1"
                        component="span"
                    >{name}</Typography>
                    {(date) && <Box
                        sx={{
                            display: 'block',
                            py: 0,
                            pb: '10px'
                        }}
                    >
                        {date && <Typography
                            variant='body2'
                            component='p'
                            sx={{ fontSize: '13px' }}
                        >{dayjs(date).format('MMMM D, YYYY')}</Typography>}

                        {booking && <Typography
                            variant='body2'
                            component='p'
                            sx={{
                                ml: '0!important',
                                fontSize: '13px'
                            }}
                        >{getAssistantsString(booking)}</Typography>}
                    </Box>}
                </Grid>
                <Grid
                    item
                    xs={2}
                    sx={{
                        px: '0!important',
                        display: 'grid'
                    }}
                >
                    <IconButton
                        onClick={() => context.handleEditTour(name)}
                        aria-label='Editar'
                        sx={{
                            height: '17px',
                            px: '3px',
                            py: 0
                        }}
                    >
                        <Edit />
                    </IconButton>
                    <IconButton
                        onClick={() => {

                            dispatch(remove(slug))
                            setTimeout(() => {
                                if (tours === 1) {
                                    navigate('/')
                                }
                            }, 100)
                        }}
                        aria-label='Remover'
                        sx={{
                            height: '17px',
                            px: '3px',
                            py: 0
                        }}
                    >
                        <Trash />
                    </IconButton>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: 500,
                        }}>
                        {`${total}€`}
                    </Typography>
                </Grid>
            </Grid>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Divider
                    aria-hidden="true"
                    sx={{
                        color: '#666B6E',
                        height: 3,
                        width: '5%'
                    }}
                />
            </Box>
        </Box>
    )
}

export default SimpleProductCard;