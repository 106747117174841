import React from 'react'
import { useTranslation } from 'react-i18next';
import {
    Box,
    FormControl,
    FormControlLabel,
    InputLabel,
    Link,
    MenuItem,
    Pagination,
    Select,
    Switch,
    Typography
} from '@mui/material';
import SearchInput from '../SearchInput';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'
import Card from './Card';

const CardsView = ({ cards, type }) => {

    const { t } = useTranslation('components');
    return (
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: {
                xs: '100%',
                md: 'repeat(4, minmax(0, 1fr));'
            },
            gap: '1rem',
            mb: '2rem',
        }}>
            {
                cards.map((card, index) => (
                    <Card
                        key={`${card.name}${index}`}
                        image={card.image}
                        href={card.path}
                        title={card.name}
                        tagText={card.cities ? `${card.cities} ${t('cities')}` : null}
                        bottomText={`+${card.activities.toLocaleString()} ${t('activities')}`}
                    />
                ))
            }
        </Box>
    )
}

const ListView = ({ cards, type }) => {
    const { t } = useTranslation('components');
    const columns = cards[0].cities ? 3 : 2;
    return (
        <Box sx={{
            display: 'grid',
            rowGap: '1rem',
            gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
            mb: { xs: '2rem', md: '4rem' },
        }} >
            <Typography color='secondary' variant='title2' component="h3">
                {type}
            </Typography>
            <Typography color='secondary' variant='title2' component="h3" sx={{ textAlign: 'right' }}>
                {t('ListView-activities')}
            </Typography>
            {columns === 3 ? (
                <Typography color='secondary' variant='title2' component="h3" sx={{ textAlign: 'right' }}>
                    {t('ListView-destinations')}
                </Typography>
            ) : null}
            {
                cards.map((card, index) => (
                    <Link
                        key={`${card.name}${index}`}
                        href={card.path}
                        sx={{
                            color: 'black',
                            gridColumn: `span ${columns}`,
                            textDecoration: 'none',
                        }}
                        underline='hover'
                    >
                        <Box sx={{
                            display: 'grid',
                            gap: '10%',
                            gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
                        }} >
                            <Typography variant='body4'>
                                {card.name}
                            </Typography>
                            <Typography variant='body4' sx={{ textAlign: 'right' }}>
                                {`+${card.activities.toLocaleString()} ${t('activities')}`}
                            </Typography>
                            {columns === 3 ? (
                                <Typography variant='body4' sx={{ textAlign: 'right' }}>
                                    {`${card.cities} ${t('cities')}`}
                                </Typography>
                            ) : null}
                        </Box>
                    </Link>
                ))
            }
        </Box>
    )
}

const CardsGrid = ({ cards, type }) => {
    const { t } = useTranslation('components');
    const [isGridView, setIsGridView] = React.useState(true)
    const [page, setPage] = React.useState(1);
    return (
        <Box>
            <Box sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: { xs: 'center', md: 'space-between' },
                mb: { xs: '1rem', md: '2rem' }
            }}>
                <Typography component='h2' variant='title' color='secondary' sx={{ mb: { xs: '1rem', md: 0 } }}>
                    {t('all')} {type.toLowerCase()}
                </Typography>
                <Box sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: { xs: 'column', md: 'row' },
                    width: { xs: '100%', md: 'auto' },
                }}>
                    <SearchInput
                        SearchIcon={SearchIcon}
                        styles={{
                            mb: { xs: '1rem', md: 0 },
                            width: { xs: '100%', md: '17rem' }
                        }}
                        placeHolder={t('searchDestinations-placeholder')}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                color='secondary'
                                onChange={() => setIsGridView(!isGridView)}
                            />
                        }
                        label={t('listview')}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: { xs: '1rem', md: 0 },
                            width: { xs: '100%', md: '10rem' }
                        }}
                    />
                    <FormControl sx={{
                        display: { xs: 'none', md: 'block' },
                        width: '10rem',
                    }}>
                        <InputLabel>{t('orderby')}</InputLabel>
                        <Select
                            label={t('orderby')}
                            onChange={() => { }}
                            sx={{ width: '100%' }}
                        >
                            <MenuItem value='asd'>{t('asc')}</MenuItem>
                            <MenuItem value='des'>{t('desc')}</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>
            {isGridView ? <CardsView cards={cards} type={type} /> : <ListView cards={cards} type={type} />}
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    mb: { xs: '2rem', md: '4rem' }
                }}>
                <Pagination
                    count={1}
                    page={page}
                    variant='outlined'
                    color='primary'
                    onChange={(evt, value) => setPage(value)}
                />
            </Box>
        </Box>
    )
}

export default CardsGrid;
