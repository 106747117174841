import React, { useState } from "react"
import { useTranslation } from 'react-i18next';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Typography,
    Button
} from "@mui/material"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const GroupCheckBoxesFilter = ({ title, checkBoxes = [], handleOnChange }) => {
    const { t } = useTranslation(['components']);
    const [showAll, setShowAll] = useState(false);

    // Asegurar que checkBoxes es un array
    const safeCheckBoxes = Array.isArray(checkBoxes) ? checkBoxes : [];

    // Dividir los checkboxes en dos grupos
    const visibleCheckBoxes = showAll ? safeCheckBoxes : safeCheckBoxes.slice(0, 4);

    return (
        <Accordion defaultExpanded sx={{ mb: '25px', borderRadius: '8px' }}>
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls={`${title}-content`}
                id={`${title}-header`}
            >
                <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                        {visibleCheckBoxes.map((checkBox) => (
                            <FormControlLabel
                                key={checkBox.name}
                                control={
                                    <Checkbox
                                        onChange={() => handleOnChange(checkBox.name)}
                                        name={checkBox.name}
                                        id={checkBox.id}
                                        checked={checkBox.isChecked}
                                    />
                                }
                                label={checkBox.name}
                            />
                        ))}
                    </FormGroup>
                    {/* Botón para mostrar más o menos */}
                    {safeCheckBoxes.length > 4 && (
                        <Button sx={{
                            color: 'rgba(0, 0, 0, 0.87)',
                            textAlign: 'left',
                            justifyContent: 'flex-start'
                        }} onClick={() => setShowAll(!showAll)}>
                            {showAll ? t('seeless') : t('seemore')}
                        </Button>
                    )}
                </FormControl>
            </AccordionDetails>
        </Accordion >
    );
};


export default GroupCheckBoxesFilter
