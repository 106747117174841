import { Box, Typography } from '@mui/material'

const GuideCard = ({ Icon, title, text, alignContent = 'center' }) => (
    <Box
        sx={{
            alignItems: alignContent,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 3,
            boxShadow: 4,
            p: '1.2rem',
            justifyContent: alignContent,
            textAlign: alignContent,
            mx: { xs: '.5rem', md: 0 },
            mt: { xs: '.2rem', md: 0 }
        }}>
        <Icon style={{ color: '#223F7F', height: '4rem', width: '5rem' }} />
        <Typography color="secondary" variant="h5" component="h3">
            {title}
        </Typography>
        <Typography
            sx={{
                color: '#3E3A39',
                fontFamily: 'Inter',
                fontSize: 18
            }}>
            {text}
        </Typography>
    </Box>
)

export default GuideCard
